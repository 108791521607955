import {Modal, Tabs} from 'antd';
import React, {useEffect, useState, useRef} from 'react';
import {getTableCodesFilterOptions, getTicketsCountText, usedMetricsT} from '../../../helpers/tickets';
import {onUpdateGuestT, useAddGuestStateT, useSetTicketStateT, useTicketsActionsT} from '../../../hooks/groups';
import {TicketGroupT, TicketTypeRow} from '../../../queries/types/groups';
import {distributionT, GroupDetailTableDataT, TicketsCardT, TicketStatusTypes} from '../../../types/tickets';
import {SelectOptionsT} from '../../../ui-kit/Select';
import {BigCard} from '../../../ui-kit/StaticCard';
import {StyledTableContentWrapper} from '../../../ui-kit/Table/styles';
import {
  ValueWrapper,
  ValueNameItem,
  ValueItem,
  HelpDescription,
  HelpPopover,
  HelpRow,
  HeadLine,
  CloseSuccess,
  SuccessBanner,
  PageWrapper,
} from './styles';
import {GroupDetailTable, ModalLvl} from './Table';
import {TableCards} from './TableCards';
import {UploadModal} from './UploadModal';
import {sendMultipleT} from '../../../hooks/emails';
import {EmailsMapT} from '../../../types/emails';
import {getFilterTableData} from '../../../helpers/helpers';
import {HELP_TITLE, CONTACTS_ADDED, HELP, SwitchModal} from '../../../constants/text';
import {TooltipIcon} from '../../../ui-kit/Tooltip/styles';
import {CloseOutlined, WarningOutlined} from '@ant-design/icons';
import {PdfContent} from './PdfContent';
import {GroupedTicketTypesT} from '../../../helpers/table';

type GroupViewProps = {
  ticket?: TicketGroupT;
  ticketLoading?: boolean;
  tableData?: GroupDetailTableDataT[];
  listLoading?: boolean;
  onSendTicket: (id?: string) => Promise<boolean>;
  onUpdate?: useTicketsActionsT['onUpdate'];
  id: string | undefined;
  refetch: () => void;
  viewMetrics?: TicketTypeRow[];
  groupsOptions?: SelectOptionsT[];
  onGroupRedirect?: (values?: any) => void;
  ticketState: useSetTicketStateT;
  groupRefetch: () => void;
  addGuestData: useAddGuestStateT;
  sendMultiple?: sendMultipleT;
  loadingSend: boolean;
  usedTickets?: usedMetricsT;
  emailsMap?: EmailsMapT;
  onUpdateGuest: onUpdateGuestT;
  searchTickets: (v: string) => void;
  onDelete?: useTicketsActionsT['onDelete'];
  modals: {
    helpModal: {
      expanded: boolean | undefined;
    };
    subsequent: boolean | undefined;
    triggerFlow: () => void;
    restoreSubsequent: () => void;
    uploadTriggered: boolean | undefined;
    isOnboarding: boolean;
    restoreOnboarding: () => void;
    triggerOnboarding?: string;
  };
  setTab?: (value: string) => void;
  tab?: string;
  groupedTicketTypes?: GroupedTicketTypesT;
};

export const GroupView: React.FC<GroupViewProps> = ({
  ticketLoading,
  ticket,
  listLoading,
  tableData,
  onSendTicket,
  onUpdate,
  id,
  refetch,
  viewMetrics,
  groupsOptions,
  onGroupRedirect,
  ticketState,
  groupRefetch,
  addGuestData,
  sendMultiple,
  loadingSend,
  usedTickets,
  emailsMap,
  onUpdateGuest,
  searchTickets,
  onDelete,
  modals,
  setTab,
  tab,
  groupedTicketTypes,
}) => {
  const [filter, setFilter] = useState<TicketStatusTypes>(TicketStatusTypes.all);
  const [filterTableData, setFilterTableData] = useState<GroupDetailTableDataT[] | undefined>();
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [uploadSuccess, setUploadSuccess] = useState<boolean | undefined>();
  const handleSetFilter = (value: TicketStatusTypes) => setFilter(value);
  const options = getTableCodesFilterOptions();
  const [pageWidth, setPageWidth] = useState<number | undefined>();
  const pageEl = useRef<HTMLHeadingElement>(null);
  const {triggerFlow, helpModal, subsequent, isOnboarding, restoreOnboarding, uploadTriggered} = modals;
  const closeBanner = () => setUploadSuccess(false);
  const cards: TicketsCardT[] = [
    {
      label: 'Tickets Uploaded',
      value: String(ticket?.ticketsUploaded),
    },
    {
      label: 'Tickets Sent',
      value: String(ticket?.ticketsSent),
      tooltip: 'The number of tickets that clients send guests',
    },
  ];

  const cardLineValues = {
    contact: [ticket?.contactPerson, ticket?.clientEmailList?.join('\n') || ticket?.clientEmail],
    ticketTypes: ticket?.ticketType?.split(','),
  };

  const cardLines = [
    {
      label: 'Contact Details',
      value: (
        <ValueWrapper>
          <ValueNameItem>{cardLineValues.contact[0]}</ValueNameItem>
          <ValueItem>{cardLineValues.contact[1]}</ValueItem>
        </ValueWrapper>
      ),
    },
    {
      label: 'Ticket Types',
      value: (
        <ValueWrapper>
          {cardLineValues?.ticketTypes?.map((el) => (
            <ValueItem key={el}>{el}</ValueItem>
          ))}
        </ValueWrapper>
      ),
    },
    {
      label: 'Details',
      value: (
        <ValueWrapper>
          <ValueItem>Order Number: {ticket?.ticketureOrderNumber}</ValueItem>
          <ValueItem>
            All tickets are applicable {ticket?.details?.sellerVenue} in {ticket?.details?.sellerName} only
          </ValueItem>
          <ValueItem>{getTicketsCountText(viewMetrics)}</ValueItem>
          <div>-</div>
          <ValueItem>{ticket?.description}</ValueItem>
        </ValueWrapper>
      ),
    },
  ];

  const openUpload = () => {
    //always show template modal
    if (subsequent || true) {
      triggerFlow();
      // restoreSubsequent();
      return;
    }
    setModalLvl(ModalLvl.success);
  };
  useEffect(() => {
    if (uploadTriggered) setModalLvl(ModalLvl.success);
  }, [uploadTriggered]);
  const onCancelUpload = () => setModalLvl(ModalLvl.closed);
  const onUploadSuccess = () => {
    setUploadSuccess((prev) => prev === undefined);
    setModalLvl(ModalLvl.closed);
  };

  useEffect(() => {
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
        modalRender: () => (
          <UploadModal
            onCancel={onCancelUpload}
            // onUpload={onUploadEmails}
            id={id}
            refetch={refetch}
            uploadTriggered={uploadTriggered}
            onUploadSuccess={onUploadSuccess}
            isOnboarding={isOnboarding}
          />
        ),
        style: {pointerEvents: 'all'},
        maskClosable: true,
        afterClose: () => {
          setModalLvl(ModalLvl.closed);
        },
      });
    }
    if (modalLvl === ModalLvl.closed) Modal.destroyAll();
  }, [modalLvl]);
  const disableScroll = false;
  useEffect(() => {
    if (!pageEl || !disableScroll) return;
    setPageWidth(pageEl?.current?.offsetWidth);
  }, []);

  useEffect(() => {
    const filterTableData = getFilterTableData(filter, tableData);
    setFilterTableData(filterTableData);
  }, [tableData, filter]);

  useEffect(() => {
    if (isOnboarding && uploadSuccess) {
      Modal.success({
        onOk: () => {
          setUploadSuccess(false);
          restoreOnboarding();
        },
        afterClose: () => {
          setUploadSuccess(false);
          restoreOnboarding();
        },
        okText: 'Done',
        title: 'Your codes have been populated.',
        content: <>{CONTACTS_ADDED}</>,
        width: 460,
        style: {pointerEvents: 'all'},
        maskClosable: true,
        centered: true,
      });
    }
  }, [isOnboarding, uploadSuccess]);
  const onTabChange = (key: any) => setTab?.(key);
  const onTabClick = (key: string) => {
    setTimeout(() => {
      tab && setTab?.(tab); //prevent switch
    }, 10);
    const switchKey = key as 'pdf' | 'email';
    if (!switchKey) return;
    Modal.warn({
      okText: SwitchModal[switchKey].okButton,
      title: SwitchModal[switchKey].title,
      content: SwitchModal[switchKey].description,
      cancelText: 'Cancel',
      maskClosable: true,
      centered: true,
      closeIcon: true,
      okCancel: true,
      wrapClassName: 'switch-modal',
      icon: <WarningOutlined />,
      onOk: () => {
        setTab?.(switchKey);
      },
    });
  };
  return (
    <PageWrapper>
      {uploadSuccess && !isOnboarding && <Success close={closeBanner} />}
      <HeadLine>
        <Help expanded={helpModal.expanded || undefined} />
      </HeadLine>
      <BigCard
        cardTitle={ticket?.clientName}
        lines={cardLines}
        additionalInfo={['Created By:', `${ticket?.user?.email}, ${ticket?.department} (${ticket?.groupType})`]}
        loading={ticketLoading && !ticket?.id}
        titleOptions={groupsOptions}
        defaultOption={groupsOptions?.find((el) => String(el?.value) === String(ticket?.id))}
        titleOnChange={onGroupRedirect}
        logo={ticket?.clientLogoUrl}
      />

      <StyledTableContentWrapper ref={pageEl}>
        <Tabs type="card" activeKey={tab} onChange={onTabChange} onTabClick={onTabClick}>
          <Tabs.TabPane tab="Distribute by Email" key={distributionT.email}>
            <>
              <TableCards
                cards={cards}
                createdAt={ticket?.createdAt}
                updatedAt={ticket?.updatedAt}
                filterValue={filter}
                setFilter={handleSetFilter}
                filterOptions={options}
                onUpload={openUpload}
              />
              <GroupDetailTable
                tickets={filterTableData}
                listLoading={listLoading}
                onSendTicket={onSendTicket}
                onUpdate={onUpdate}
                viewMetrics={viewMetrics}
                ticketState={ticketState}
                groupRefetch={groupRefetch}
                addGuestData={addGuestData}
                sendMultiple={sendMultiple}
                loadingSend={loadingSend}
                usedTickets={usedTickets}
                emailsMap={emailsMap}
                onUpdateGuest={onUpdateGuest}
                searchTickets={searchTickets}
                pageWidth={pageWidth}
                onDelete={onDelete}
                filter={filter}
                setFilter={setFilter}
                groupedTicketTypes={groupedTicketTypes}
                groupId={ticket?.id}
              />
            </>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Distribute by PDF" key={distributionT.pdf}>
            <PdfContent groupId={id} tickets={ticket?.ticketsUploaded} />
          </Tabs.TabPane>
        </Tabs>
      </StyledTableContentWrapper>
    </PageWrapper>
  );
};

const Help: React.FC<{expanded?: boolean}> = ({expanded}) => {
  return (
    <HelpPopover
      placement="bottomRight"
      title={HELP_TITLE}
      content={
        <HelpDescription>
          <p>{HELP.pt1}</p>
          <p>{HELP.pt2}</p>
          <p>{HELP.pt3}</p>
          <p>{HELP.pt4}</p>
          <p>{HELP.pt5}</p>
        </HelpDescription>
      }
      trigger="click"
      visible={expanded}>
      <HelpRow>
        Need Help?
        <TooltipIcon />
      </HelpRow>
    </HelpPopover>
  );
};

const Success: React.FC<{close: () => void}> = ({close}) => {
  return (
    <SuccessBanner>
      <p>
        <b>Your contacts have been added.</b>
      </p>
      <p>
        Please review your contact details before sending out their tickets. You can edit contacts by clicking “Edit”
        and adjusting their name, email, or ticket quantities, then clicking “Save.”
      </p>
      <p>
        Once you have verified your contacts, click “Bulk Send Tickets” at the bottom of the page to send emails to all
        your new or updated contacts. You can also send tickets to a specific contact by clicking “Send” to the right of
        their name.
      </p>
      <CloseSuccess onClick={close}>
        <CloseOutlined />
      </CloseSuccess>
    </SuccessBanner>
  );
};
