import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

export const EmptyGroupState = () => {
  return (
    <Page>
      <Title>This group is not available.</Title>
      <LinkButton to={'/'}>GO BACK</LinkButton>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 6px;
  height: 100%;
`;

const Title = styled.div`
  font-size: 20px;
`;

const LinkButton = styled(Link)`
  display: flex;
  align-items: center;

  width: auto;
  height: 29px;
  padding: 0 10px;
  box-sizing: border-box;

  border-radius: 4px;
  border: 1px solid #020e16;
  max-width: 100px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #020e16;

  text-decoration: none;
`;
