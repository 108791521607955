import {themes} from '../ui-kit/theme/theme';
import {THEME} from './env';

const TERMS_LINKS = {
  [themes.enchant]: 'https://www.enchantchristmas.com/legal/terms-conditions',
  [themes.coke]: 'https://www.classicchristmas.com/legal/terms-conditions',
  [themes.lite]: 'https://jingleholiday.com/legal/terms-conditions',
};

const POLICY_LINKS = {
  [themes.enchant]: 'https://www.enchantchristmas.com/legal/privacy-policy',
  [themes.coke]: 'https://www.classicchristmas.com/legal/privacy-policy',
  [themes.lite]: 'https://jingleholiday.com/legal/privacy-policy',
};

export const TERMS_LINK = TERMS_LINKS[(THEME as themes) || themes.enchant];
export const POLICY_LINK = POLICY_LINKS[(THEME as themes) || themes.enchant];
