import React, {useEffect, useState} from 'react';
import {useGetGroups, useGetTicketGroup} from '../../hooks/groups';
import {Distribution} from '../../components/Distribution';
import {useDistribution, usePdf} from '../../hooks/onboarding';
import {DistributionUpload} from '../../components/Distribution/Upload';

export const DistributionPage = () => {
  const {groups, loading: groupsLoading} = useGetGroups();
  const [selectedGroup, setSelectedGroup] = useState(groups[0]?.id);
  const onChangeGroup = (value: any) => setSelectedGroup(value);
  const {loading: downloadLoading, checkAndGenerate, ...pdfProps} = usePdf(String(selectedGroup || ''));
  const {
    goGroups,
    method,
    selectEmail,
    selectPdf,
    success,
    loading,
    generatePdf,
    isUploading,
    cancelUploading,
    pdfType,
    error,
  } = useDistribution(checkAndGenerate);

  const {metrics, loading: metricsLoading} = useGetTicketGroup(
    selectedGroup !== undefined ? String(selectedGroup) : '',
  );
  const ticketCount = metrics?.reduce((prev, current) => prev + (current?.ticketTypeUploaded || 0), 0);
  useEffect(() => {
    setSelectedGroup(groups[0]?.id);
  }, [!!groups?.length]);

  if (isUploading)
    return (
      <DistributionUpload
        cancelUploading={cancelUploading}
        success={success}
        loading={loading}
        goGroups={goGroups}
        pdfType={pdfType}
        error={error}
        ticketsCount={ticketCount}
        downloadLoading={downloadLoading}
        {...pdfProps}
      />
    );
  return (
    <Distribution
      groups={groups}
      goGroups={goGroups}
      method={method}
      selectEmail={selectEmail}
      selectPdf={selectPdf}
      generatePdf={generatePdf}
      selectedGroup={selectedGroup}
      onChangeGroup={onChangeGroup}
      metricsLoading={(metricsLoading || groupsLoading) && !selectedGroup}
    />
  );
};
