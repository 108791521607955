import React, {useState} from 'react';
import {GroupView} from '../../components/Groups/View/View';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useGetTicketGroup,
  useGetListTickets,
  useSendTicket,
  useGetGroups,
  useSetTicketState,
  useMultipleUpdate,
  useMultipleSend,
  useAddGuests,
  useUpdateGuest,
  useTicketsActions,
} from '../../hooks/groups';
import {GroupFooterContainer} from '../Layout/Footer';
import {route} from '../../constants/routes';
import {BrowserView, MobileView} from 'react-device-detect';
import {useMultipleSendbyEmails} from '../../hooks/emails';
import {notification} from 'antd';
import {Onboarding} from './Onboarding';
import {useHelpModal} from '../../hooks/onboarding';
import {useChangeDistribution, useViewer} from '../../hooks/auth';
import {distributionT} from '../../types/tickets';
// import {useUploadEmails} from '../../hooks/emails';

export const openNotifError = (message?: string) => {
  console.log('e:', message);
  notification['error']({
    message: 'Error',
    description: message,
  });
};

export const Group = () => {
  const {id} = useParams();
  const viewer = useViewer();
  const [tab, setTab] = useState<string>(viewer?.metadata?.distributionType || distributionT.email);
  const navigate = useNavigate();
  const {group, loading, refetch: gRefetch, metrics, groupedTicketTypes} = useGetTicketGroup(id);
  const {
    tableData,
    loading: listLoading,
    refetch,
    usedTickets,
    emailsMap,
    searchTickets,
  } = useGetListTickets(id, metrics);
  const handleRefetch = () => {
    gRefetch();
    refetch();
  };

  const {groupsOptions} = useGetGroups();
  const {onSend} = useSendTicket(id, handleRefetch);
  const {onUpdateGuest} = useUpdateGuest(id, handleRefetch, openNotifError);
  const groupRedirect = (value?: any) => value && navigate(route.groupView.get({id: String(value)}));
  const addGuestData = useAddGuests(metrics, usedTickets, id, handleRefetch);
  const ticketState = useSetTicketState();
  const {updateAll} = useMultipleUpdate({groupId: id});
  const {sendAll} = useMultipleSend({groupId: id, onSuccess: ticketState.reset});
  const {sendMultiple, loadingSend} = useMultipleSendbyEmails(id, handleRefetch, openNotifError);
  const onBulk = async () => {
    await addGuestData.onAddGuest();
    await updateAll(ticketState.state);
    await sendAll(ticketState.state);
  };
  const {onDelete} = useTicketsActions(id, handleRefetch, openNotifError);
  const modals = useHelpModal();
  const {updateDistribution} = useChangeDistribution();
  const onTabChange = (key: string) => {
    updateDistribution(key);
    setTab(key);
  };
  return (
    <>
      <Onboarding id={id} />
      <BrowserView>
        <GroupView
          ticket={group}
          ticketLoading={loading}
          tableData={tableData}
          listLoading={listLoading}
          // onUpload={onUpload}
          // uploadSuccess={success?.status}
          // uploadLoading={uploadLoading}
          // uploadError={error}
          onSendTicket={onSend}
          onUpdateGuest={onUpdateGuest}
          id={id}
          refetch={refetch}
          viewMetrics={metrics}
          groupsOptions={groupsOptions}
          onGroupRedirect={groupRedirect}
          ticketState={ticketState}
          groupRefetch={gRefetch}
          addGuestData={addGuestData}
          sendMultiple={sendMultiple}
          loadingSend={loadingSend}
          usedTickets={usedTickets}
          emailsMap={emailsMap}
          searchTickets={searchTickets}
          onDelete={onDelete}
          modals={modals}
          setTab={onTabChange}
          tab={tab}
          groupedTicketTypes={groupedTicketTypes}
        />
        <GroupFooterContainer onSuccess={handleRefetch} onBulk={onBulk} tab={tab} />
      </BrowserView>
      <MobileView>
        <div>test mobile</div>
      </MobileView>
    </>
  );
};
