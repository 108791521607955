import React from 'react';
import {useGetGroups} from '../../hooks/groups';
// import {GroupsPage} from '../../components/Groups';
import {PageLoader} from '../../ui-kit/Loader';
import {Navigate} from 'react-router-dom';
import {route} from '../../constants/routes';

// export const Groups = () => {
//   const {groups} = useGetGroups();

//   return <GroupsPage groups={groups} />;
// };

export const Groups = () => {
  const {groups, loading} = useGetGroups();
  if (loading) return <PageLoader />;

  const lastGroup = groups?.[0]?.id;
  if (lastGroup) return <Navigate to={route.groupView.get({id: String(lastGroup)})} />;

  return null;
};
