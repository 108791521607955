import React from 'react';
import styled from 'styled-components';
import {TicketT} from '../../external_usage';
import {pluralize} from '../../helpers/common';

export const StatusLabels = ({tickets}: {tickets?: TicketT[]}) => {
  const sent = tickets?.filter((el) => el?.outboundStatus === 'sent');
  const notsent = tickets?.filter((el) => el?.outboundStatus === 'notsent');
  const sentCount = sent?.length ? pluralize(sent.length, 'ticket') : '';
  const notsentCount = notsent?.length ? pluralize(notsent.length, 'ticket') : '';

  // const copySentTickets = () => {
  //   navigator.clipboard.writeText(sent?.map((el) => el?.code)?.join(', ') || '');
  //   notification.success({
  //     message: `${sentCount} copied to clipboard!`,
  //     placement: 'topRight',
  //     duration: 1,
  //   });
  // };
  // const copyNotSentTickets = () => {
  //   navigator.clipboard.writeText(notsent?.map((el) => el?.code)?.join(', ') || '');
  //   notification.success({
  //     message: `${notsentCount} copied to clipboard!`,
  //     placement: 'topRight',
  //     duration: 1,
  //   });
  // };
  return (
    <Statuses>
      {!!sentCount && <StatusLabel variant="primary">{sentCount} sent</StatusLabel>}
      {!!notsentCount && <StatusLabel variant="secondary">{notsentCount} assigned</StatusLabel>}
    </Statuses>
  );
};

const Statuses = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
`;

const StatusLabel = styled.div<{variant: 'primary' | 'secondary'}>`
  padding: 2px 4px 2px 4px;
  border-radius: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;

  ${({variant}) => {
    if (variant === 'primary')
      return `
  background: #227B75;
  color: #fff;
  `;
    if (variant === 'secondary')
      return `
  background: #BECDCC;
  color: #000000;
  `;
  }}
`;
