import React from 'react';
import {LogInPage} from '../../components/Auth/LogIn';
import {useLogin} from '../../hooks/auth';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';

export const LogIn = () => {
  const navigate = useNavigate();
  const goToWelcome = () => navigate(route.welcomeScreen.path);
  const authData = useLogin(goToWelcome);
  return <LogInPage authData={authData} />;
};
