import React, {useState} from 'react';
import {sortOptions} from '../../helpers/guests';
import styled from 'styled-components';
import {buttonReset} from '../../helpers/common';
import {SvgIcon} from '../Common/Icon';
import {Dropdown, Menu} from 'antd';
import {ItemType} from 'antd/lib/menu/hooks/useItems';

export const GuestsSort = ({handleSort}: {handleSort?: (val?: string) => void}) => {
  const [isEdited, setIsEdited] = useState(false);
  const [value, setValue] = useState(sortOptions.recently);

  const onValueChange = (val: string) => () => {
    setValue(val);
    setIsEdited(true);
    handleSort?.(val);
  };

  const ddActions: ItemType[] = Object.values(sortOptions).map((el, i) => ({
    key: i,
    label: <div onClick={onValueChange(el)}>{el}</div>,
  }));

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <Button>
        {isEdited ? value : 'Sort By'}
        <SvgIcon type="chevronDown" />
      </Button>
    </Dropdown>
  );
};

const Button = styled.button`
  ${buttonReset};
  border: 1px solid #cccfd0;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 7px;
  height: 100%;
  box-sizing: border-box;
  max-height: 30px;
`;
