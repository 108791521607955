import styled, {css} from 'styled-components';
import {Card, Popover, Select, Typography} from 'antd';
import {Media} from '../../../ui-kit/theme/breakpoints';
import {Button, InfoButton} from '../../../ui-kit/Button';
import Input from '../../../ui-kit/Input';
import {PrimaryTable, TableWrapper} from '../../../ui-kit/Table';
import {StaticCard} from '../../../ui-kit/StaticCard';
import {StyledTableContentWrapper} from '../../../ui-kit/Table/styles';
import {ValidationTag} from '../styles';
import {QuestionCircleFilled} from '@ant-design/icons';
const {Text} = Typography;

const buttonStyle = css`
  border: none;
  background-color: transparent;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  padding-right: 0;
  :hover {
    background-color: transparent;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 190px 32px 190px;
  ${Media.down.xl} {
    padding: 15px 20px;
  }
  .ant-tabs-nav {
    margin-bottom: 10px;
  }
  .ant-tabs-tab {
    border-radius: 18px 18px 0 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    margin-left: 0 !important;
    background-color: ${({theme}) => theme.palette.background.newGray} !important;
  }
  .ant-tabs-tab-btn {
    color: ${({theme}) => theme.palette.common.black} !important;
    text-shadow: unset !important;
    font-weight: 600;
  }
  .ant-tabs-tab-active {
    background-color: ${({theme}) => theme.palette.background.white} !important;
  }
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const CardInfoRow = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

export const CardInfoValue = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.mediumBold};
  font-size: 14px;
  line-height: 22px;
`;

export const TableCardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const EventCardsWrapper = styled(TableCardsWrapper)`
  margin-bottom: 8px;
`;

export const EventTableWrapper = styled(StyledTableContentWrapper)`
  margin-bottom: 32px;
`;

export const TableCardsOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const UploadButtonWrapper = styled.div``;

export const UploadButton = styled(Button)`
  margin-bottom: 8px;
  width: 138px;
  ${Media.down.l} {
    width: 120px;
  }
`;

export const ActionBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  color: ${({theme}) => theme.palette.common.brown};
`;

export const CardsRow = styled.div`
  display: flex;
  ${Media.down.l} {
    flex-wrap: wrap;
  }
`;

export const TableTitleCard = styled(Card).attrs({
  size: 'small',
})`
  margin-right: 14px;
`;

export const CardDateInfoLabel = styled(Text).attrs({
  type: 'secondary',
})`
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: 14px;
  line-height: 22px;
  margin-right: 6px;
  width: 70px;
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnsLabel = styled.span`
  position: absolute;
  top: 10px;
  width: 100%;
  left: 0;
  border-bottom: 2px solid #e6e6e6;
  font-size: 14px;
  text-transform: capitalize;
  z-index: 1;
  text-align: center;
  box-sizing: border-box;
  padding-left: 40px;
`;

export const TableRootTitle = styled.div`
  text-align: center;
`;

export const StyledTableWrapper = styled(TableWrapper)`
  position: relative;
  overflow: hidden;
  padding-top: 20px;
`;

export const ValueItem = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.regular};
`;

export const ValueNameItem = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
`;

export const MenuAction = styled.div<{$danger?: boolean}>`
  color: ${({theme, $danger}) => (!$danger ? theme.palette.text.primary : theme.palette.common.red)};
`;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const CodeColumn = styled.div``;

export const CodeInput = styled(Input)`
  max-width: 200px;
  height: 32px;
`;

export const StyledCard = styled(Card)`
  width: 233px;
  & .ant-card-body {
    padding: 20px;
  }
`;

export const TicketsUsageStaticCard = styled(StaticCard)`
  margin-right: 0px;
  & .ant-card-body {
    padding: 4px 10px;
  }
`;

export const CardColumn = styled.div`
  margin-bottom: 12px;
`;

export const ViewTable = styled(PrimaryTable)<{$addShadow?: boolean}>`
  & .ant-table table {
    border-spacing: 0 !important;
    th {
      border: 1px solid rgba(0, 0, 0, 0.04) !important;
      padding: 2px 16px;
    }
  }
  & .ant-table-container table > thead th > div {
    width: 140px;
    text-align: center;
    padding: 6px 0px;
    box-sizing: border-box;
    margin: 0 auto;
  }
  & .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 25px;
    border-right: 2px solid #e6e6e6;
    & > div {
      text-align: unset;
      padding-top: 0;
      margin: unset;
    }
  }
  & .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: right;
    padding-right: 25px;
    border-left: 2px solid #e6e6e6;
    & > div {
      padding-top: 0;
      margin: unset;
    }
  }
  & .ant-table-tbody tr td {
    & > div {
      width: 140px;
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
  }
  & .ant-table-tbody tr td:first-of-type {
    & > div {
      width: 200px;
      margin: unset;
    }
    border-right: 2px solid #f0f0f1;
  }
  & .ant-table-tbody tr td:first-of-type {
    border-left: none;
  }
  & .ant-table-tbody tr td:last-of-type {
    border-right: none;
    border-left: 2px solid #f0f0f1;
    & > div {
      width: auto;
      margin: unset;
      justify-content: flex-end;
    }
  }
  & .ant-table-tbody tr td input {
    width: 210px;
    ${Media.down.l} {
      width: 160px;
    }
  }
  ${({$addShadow}) =>
    $addShadow &&
    `
    & .ant-table tr {
      td, th {
        &:first-of-type {
          box-shadow: 10px 0px 9px 0px rgb(225 225 225 / 22%);
        }
        &:last-of-type {
          box-shadow: -10px 0px 9px 0px rgb(225 225 225 / 22%);
        }
      }
    }
  `}
  & .ant-table {
    width: 100%;
    overflow-x: auto;
  }
`;

export const TableTitleSpan = styled.span`
  font-size: 10px;
`;

export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  width: 100px;
`;

export const SearchBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  span > svg {
    color: ${({theme}) => theme.palette.common.black};
    font-size: 20px;
  }
  :active,
  :hover,
  :focus {
    background-color: transparent;
  }
`;

export const SearchWrapper = styled.div`
  padding: 15px 19px;
  background-color: ${({theme}) => theme.palette.common.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
`;

export const EnterButton = styled(Button)`
  width: 129px;
  height: 36px;
  border-radius: 40px;
  margin-left: 8px;
`;

export const SearchInput = styled(Input)`
  border-radius: 4px;
  min-width: 300px;
  height: 36px;
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
`;

export const SearchResultWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
`;

export const ResultLabel = styled.span``;

export const ClearButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
`;

export const TableItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CustomInfoButton = styled(InfoButton)`
  margin-right: 6px;
`;

export const MaxTicketsLabel = styled(ValidationTag)`
  position: absolute;
  bottom: 3px;
  right: 2px;
  width: auto;
  background-color: ${({theme}) => theme.palette.common.redBrown};
  border: none;
  opacity: 0.9;
  height: 21px;
  font-size: 12px;
  align-items: center;
  display: flex;
`;

export const QuestionCircleStyled = styled(QuestionCircleFilled)`
  width: 20px;
  height: 20px;
`;

export const WrapperLinks = styled.div`
  display: flex;
  align-items: baseline;
`;

export const HeadLine = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const HelpRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const HelpDescription = styled.div`
  width: 360px;
  p {
    padding: 0;
    margin-bottom: 12px;
  }
`;

export const HelpPopover = styled(Popover)`
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ContentWrapper = styled.div<{$no_mg?: boolean}>`
  margin-top: ${({$no_mg}) => ($no_mg ? '0' : '25px')};
  p {
    margin-top: 0;
    padding-top: 0;
  }
  b {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const GoNextButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  height: 40px;
`;

export const MethodsWrapper = styled.div`
  margin-top: 25px;
`;

export const MethodButton = styled.button`
  width: 400px;
  min-height: 105px;
  padding: 26px 16px 10px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  background-color: #becccc;

  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 16px;
  img {
    display: block;
    width: auto;
    margin-right: 20px;
    margin-left: 10px;
  }
  position: relative;
`;

export const ButtonLabel = styled.div`
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #227b75;
  position: absolute;
  top: -10px;
  left: 10px;
  color: #fff;
  font-size: 12px;
`;

export const MethodWrap = styled.div``;

export const MethodTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-bottom: 6px;
`;

export const MethodDescr = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 18px;
  padding-right: 10px;
`;

export const Video = styled.iframe`
  width: 100%;
  height: 220px;
  margin: 0 auto;
  background-color: #d9d9d9;
  margin-bottom: 30px;
  border: none;
`;

export const ButtonsPair = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Delimetr = styled.div`
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background-color: #cccfd0;
  gap: 10px;
`;

export const DownloadButton = styled(Button)`
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    margin-right: 10px;
  }
  font-size: 12px;
  margin-right: 0 !important;
  height: 40px;
`;

export const WideButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-top: 15px;
`;

export const MoreInfoRow = styled.div`
  margin-top: 10px;
  b {
    font-size: 14px;
  }
  a {
    font-weight: bold;
    font-size: 14px;
    color: #937e6b;
    text-decoration: underline #937e6b;
  }
`;

export const SuccessBanner = styled.div`
  width: 100%;
  padding: 26px 20px;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  background-color: ${({theme}) => theme.palette.common.skyBlue};
  margin-bottom: 10px;
  p {
    padding: 3px 0;
    margin: 0;
  }
  border-bottom: 1px solid ${({theme}) => theme.palette.common.midnightBlue};
  box-shadow: 1px 3px 4px 1px rgba(0, 0, 0, 0.2);
`;

export const CloseSuccess = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const SizeContent = styled.div<{size: string}>`
  .ant-upload-list-item-name::after {
    ${({size}) => size && `content: "${size}"`};
    font-size: 11px;
    padding-left: 4px;
  }
`;

export const PdfContentWrapper = styled.div`
  padding: 40px 20px;
  box-sizing: border-box;
  text-align: center;
`;

export const PdfContentTitle = styled.p`
  font-size: 20px;
  text-align: center;
`;

export const PdfGeneratedTitle = styled.p`
  span {
    font-weight: bold;
  }
  font-size: 17px;
  text-align: center;
`;

export const PdfGeneratedHint = styled.p`
  font-size: 13px;
  text-align: center;
`;

export const PdfActions = styled.div<{$loading?: boolean}>`
  display: flex;
  justify-content: center;
  align-self: center;
  gap: 20px;
  margin-top: 30px;
  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: ${({$loading}) => ($loading ? 0.5 : 1)};
  }

  position: relative;
  .ant-spin {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 10px;
    z-index: 1;
  }
`;

export const PdfButton = styled(Button)`
  width: 216px;
  height: 40px;
`;
