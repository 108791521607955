import React, {useEffect} from 'react';
import {OnboardingModals} from '../../components/Groups/View/OnboardingModals';
import {useModalsFlow} from '../../hooks/onboarding';
import {useGetCsv} from '../../hooks/groups';

export const Onboarding: React.FC<{id?: string}> = ({id}) => {
  const {showOnboarding, choseBulk, choseIndividual, step, goMethods, goUpload, onFinish, onCloseFlowModals} =
    useModalsFlow();
  const csv = useGetCsv(String(id));
  useEffect(() => {
    id && csv.onDownload();
  }, [id]);
  return (
    <OnboardingModals
      showOnboarding={showOnboarding}
      choseBulk={choseBulk}
      choseIndividual={choseIndividual}
      step={step}
      goMethods={goMethods}
      goUpload={goUpload}
      onFinish={onFinish}
      csv={csv}
      onCloseFlowModals={onCloseFlowModals}
    />
  );
};
