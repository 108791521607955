import React from 'react';
import {WithChildren} from '../../types/helpers';
import {AppLayout} from '../../ui-kit/AppLayout';
import Footer from './Footer';
import {Header} from '../../ui-kit/AppLayout/Header';
import {useLogOut, useViewer} from '../../hooks/auth';
import {useLocation} from 'react-router-dom';

export const Layout: React.FC<WithChildren> = ({children}) => {
  const isAuth = useViewer();
  const {pathname} = useLocation();
  const logout = useLogOut();
  const rightText = 'Hello';
  const leftText = 'GROUP TICKETING CLIENT PORTAL';

  const footer = <Footer />;
  const header = isAuth ? <Header onLogout={logout} rightText={rightText} leftText={leftText} /> : null;
  return (
    <AppLayout
      hideFooter={
        pathname.includes('magic-link-access') ||
        pathname.includes('login') ||
        pathname.includes('forgot-password') ||
        pathname.includes('reset-password') ||
        pathname.includes('cover') ||
        pathname.includes('welcome') ||
        pathname.includes('distribution')
          ? true
          : false
      }
      hideHeader={
        pathname.includes('magic-link-access') ||
        pathname.includes('login') ||
        pathname.includes('forgot-password') ||
        pathname.includes('reset-password') ||
        pathname.includes('cover')
          ? true
          : false
      }
      footer={footer}
      header={header}>
      {children}
    </AppLayout>
  );
};
