import React, {useState} from 'react';
import styled from 'styled-components';
import {PrimaryButton} from '../Common/Button/PrimaryButton';
import {buttonReset} from '../../helpers/common';
import {Input} from 'antd';
import {SvgIcon} from '../Common/Icon';

type PropsT = {
  closeSearch?: () => void;
  handleSearch?: (value?: string) => void;
};

export const GuestsSearch: React.FC<PropsT> = ({closeSearch, handleSearch}) => {
  const [value, setValue] = useState<string | undefined>('');
  const onChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target.value);
    if (!e?.target.value) handleSearch?.('');
  };

  const onClose = () => {
    handleSearch?.('');
    closeSearch?.();
  };

  const onSearch = () => handleSearch?.(value);
  return (
    <Wrapper>
      <CloseButton onClick={onClose}>
        <SvgIcon type="cross" />
      </CloseButton>
      <TextInput placeholder="Search by name or email..." onChange={onChange} value={value} />
      <SearchButton onClick={onSearch}>Search</SearchButton>
    </Wrapper>
  );
};

export const GuestSearchResult = ({searchBy, searchedCount}: {searchBy?: string; searchedCount?: number}) => {
  return (
    <>
      {searchBy && <SearchLabel>{`"${searchBy || ''}" (${searchedCount || 0})`}</SearchLabel>}
      {searchBy && !searchedCount && (
        <NotFoundBlock>
          <h5>Not Found.</h5>
          <p>Sorry, there are no results for {`"${searchBy || ''}"`}. Please try again.</p>
        </NotFoundBlock>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  gap: 8px;
`;

const TextInput = styled(Input)`
  height: 30px;
  border-radius: 4px;
  flex: 1;
`;

const CloseButton = styled.button`
  ${buttonReset};
  border: 1px solid #cccfd0;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
`;

const SearchButton = styled(PrimaryButton)`
  width: auto;
  max-height: 30px;
  border-radius: 20px;
`;

const SearchLabel = styled.div`
  margin: 4px 0;
  color: #020e16;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
`;

const NotFoundBlock = styled.div`
  padding: 24px 30px 24px 30px;
  border-radius: 4px;
  gap: 10px;
  background: #f5f5f6;
  box-sizing: border-box;

  text-align: center;

  font-size: 12px;
  h5 {
    margin: 6px 0;
    font-size: 13px;
    font-weight: 700;
  }
  p {
    margin: 6px 0;
    padding: 0 4%;
  }
`;
