import React from 'react';
import {
  CardDateInfoLabel,
  CardInfoRow,
  CardInfoValue,
  CardsRow,
  TableCardsOptionsWrapper,
  TableCardsWrapper,
  TableTitleCard,
  UploadButton,
  UploadButtonWrapper,
} from './styles';
import {Select} from 'antd';
import {format} from 'date-fns';
import {SelectOptionT} from '../../../types/helpers';
import {TicketsCardT, TicketStatusTypes} from '../../../types/tickets';
import {StaticCard} from '../../../ui-kit/StaticCard';
import {ButtonTypes} from '../../../ui-kit/Button';

type TableCardsProps = {
  cards?: TicketsCardT[];
  createdAt?: string | number;
  updatedAt?: string | number;
  filterValue?: TicketStatusTypes;
  setFilter?: (v: TicketStatusTypes) => void;
  filterOptions?: SelectOptionT[];
  upper?: boolean;
  onUpload?: () => void;
};

export const TableCards: React.FC<TableCardsProps> = ({
  cards,
  createdAt,
  updatedAt,
  filterValue,
  setFilter,
  filterOptions,
  onUpload,
}) => {
  return (
    <TableCardsWrapper>
      <CardsRow>
        <TableTitleCard>
          <CardInfoRow>
            <CardDateInfoLabel>Created</CardDateInfoLabel>
            <CardInfoValue>{createdAt && format(new Date(createdAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
          <CardInfoRow>
            <CardDateInfoLabel>Updated</CardDateInfoLabel>
            <CardInfoValue>{updatedAt && format(new Date(updatedAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
        </TableTitleCard>
        {cards?.map((el) => (
          <StaticCard key={el.label} {...el} />
        ))}
      </CardsRow>
      <TableCardsOptionsWrapper>
        <UploadButtonWrapper>
          <UploadButton type={'primary'} variant={ButtonTypes.secondary} onClick={onUpload}>
            Upload Emails
          </UploadButton>
        </UploadButtonWrapper>
        <Select options={filterOptions} value={filterValue} onChange={setFilter} />
      </TableCardsOptionsWrapper>
    </TableCardsWrapper>
  );
};
