import React from 'react';
import {LoaderWrapper, PageWrapper} from './styles';
import {Loader} from '../../ui-kit/Loader';

export const MagicLinkReceived = () => {
  return (
    <PageWrapper>
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    </PageWrapper>
  );
};
