import React, {useState} from 'react';
import styled from 'styled-components';
import {buttonReset, pluralize} from '../../helpers/common';
import {SvgIcon} from '../Common/Icon';
import {GuestsSearch} from './GuestsSearch';
import {GuestsSort} from './GuestsSort';

type PropsT = {
  count?: number;
  handleSearch?: (value?: string) => void;
  handleSort?: (value?: string) => void;
  searchedCount?: number;
};

export const GuestListHeader: React.FC<PropsT> = ({count, handleSearch, handleSort}) => {
  const [isSearch, setIsSearch] = useState(false);
  const openSearch = () => setIsSearch(true);
  const closeSearch = () => setIsSearch(false);

  if (isSearch) return <GuestsSearch closeSearch={closeSearch} handleSearch={handleSearch} />;
  return (
    <Wrapper>
      <Label>{pluralize(count || 0, 'guest')}</Label>
      <Actions>
        <Button onClick={openSearch}>
          <SvgIcon type="search" />
        </Button>
        <GuestsSort handleSort={handleSort} />
      </Actions>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: space-between;
`;
const Label = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;

const Actions = styled.div`
  display: flex;

  align-items: center;
  gap: 8px;
`;

const Button = styled.button`
  ${buttonReset};
  border: 1px solid #cccfd0;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  padding: 0;
`;
