import React, {useEffect} from 'react';
import {useGuestsState} from '../../hooks/state';
import {GuestListLayout} from '../../components/Guests/GuestListLayout';
import {GuestListHeader} from '../../components/Guests/GuestListHeader';
import {StarLine} from '../../components/Common/StarLine';
import {GuestCard} from '../../components/Guests/GuestCard';
import {PageLoader} from '../../components/Common/PageLoader';
import {useGuestMenuActions, useGuestsSearch, useGuestsSort} from '../../hooks/guests';

type PropsT = {
  groupId?: string | number;
};

export const GuestList: React.FC<PropsT> = ({groupId}) => {
  const {guests, getGuestList, loading, refetchGuestList} = useGuestsState();
  const guestCount = guests?.length || 0;
  const {openConfirmSendModal, goGuestEdit} = useGuestMenuActions(refetchGuestList);

  const {searchedGuests, onSearch, searchBy} = useGuestsSearch(guests);
  const {sortedGuests, handleSort} = useGuestsSort(searchedGuests);
  useEffect(() => {
    getGuestList(groupId);
  }, [groupId]);

  if (loading) return <PageLoader />;
  return (
    <GuestListLayout
      header={<GuestListHeader count={guestCount} handleSearch={onSearch} handleSort={handleSort} />}
      guestCount={guestCount}
      searchedCount={searchedGuests?.length}
      searchBy={searchBy}>
      {sortedGuests?.map((el) => (
        <GuestCard key={el?.guestId} guest={el} handleSend={openConfirmSendModal} handleEdit={goGuestEdit} />
      ))}
      <StarLine />
    </GuestListLayout>
  );
};
