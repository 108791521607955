import React from 'react';
import {
  ActionButton,
  ButtonsRow,
  DistributionPageWrapper,
  ModalTitle,
  UploadDescr,
  UploadIconRow,
  UploadTitle,
  UploadingModalWrapper,
  UploadingWindow,
} from './styles';
import {Loader} from '../../ui-kit/Loader';
import {CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons';
import {ButtonTypes} from '../../ui-kit/Button';

type DistributionUploadPropsT = {
  loading?: boolean;
  success?: any;
  error?: any;
  cancelUploading: () => void;
  pdfType?: string;
  goGroups: () => void;
  ticketsCount?: number;
  metricsLoading?: boolean;
  onIndividualDownload?: () => void;
  onSingleDownload?: () => void;
  downloadLoading?: boolean;
  url_individual?: string;
  name_individual?: string;
  name_single?: string;
  url_single?: string;
};

export const DistributionUpload: React.FC<DistributionUploadPropsT> = ({
  loading,
  success,
  pdfType,
  cancelUploading,
  goGroups,
  error,
  ticketsCount,
  onIndividualDownload,
  onSingleDownload,
  url_individual,
  url_single,
  name_individual,
  name_single,
  downloadLoading,
}) => {
  const isIndividual = pdfType === 'individual';
  const uploadTitle = loading
    ? isIndividual
      ? `We are creating your zip file containing ${ticketsCount} PDFs`
      : `We are preparing your file with ${ticketsCount} tickets. . .`
    : success
    ? `The file has been successfully created`
    : '';

  const uploadDescr = loading
    ? isIndividual
      ? `You can wait and download the file once it’s ready, or go to your Order Details page, and we will email you when the file is created and ready to download.`
      : 'You can wait and download the file once it’s ready, or go to your Order Details page, and we will email you when the file is created and ready to download.'
    : success
    ? isIndividual
      ? `Your ${ticketsCount} tickets have been saved as individual PDFs ‘
    and compressed into one zip file.`
      : `Your PDF contains ${ticketsCount} tickets`
    : '';

  const successTitle = success && !loading ? (isIndividual ? 'Zip File created' : 'File Created') : '';
  const title = isIndividual ? 'Creating Zip File' : 'Creating File';
  return (
    <DistributionPageWrapper>
      <UploadingModalWrapper>
        <ModalTitle>{successTitle || title}</ModalTitle>
        <UploadingWindow>
          <UploadIconRow>
            {loading && <Loader />}
            {success && !loading && <CheckCircleFilled />}
            {error && !loading && <CloseCircleFilled />}
          </UploadIconRow>
          <UploadTitle $loading={loading}>{uploadTitle}</UploadTitle>
          <UploadDescr $loading={loading}>{uploadDescr}</UploadDescr>
        </UploadingWindow>
        <ButtonsRow>
          {(loading || error) && (
            <>
              <ActionButton variant={ButtonTypes.secondary} onClick={cancelUploading}>
                Cancel
              </ActionButton>
              <ActionButton type="primary" onClick={goGroups}>
                Go To Order Details
              </ActionButton>
            </>
          )}
          {!loading && success && isIndividual && (
            <>
              <ActionButton variant={ButtonTypes.secondary} onClick={goGroups}>
                Go To Order Details
              </ActionButton>
              <ActionButton
                type="primary"
                onClick={onIndividualDownload}
                download={name_individual}
                href={url_individual}
                loading={downloadLoading}>
                Download PDF (Zip file)
              </ActionButton>
            </>
          )}
          {!loading && success && !isIndividual && (
            <>
              <ActionButton variant={ButtonTypes.secondary} onClick={goGroups}>
                Go To Order Details
              </ActionButton>
              <ActionButton
                type="primary"
                onClick={onSingleDownload}
                download={name_single}
                href={url_single}
                loading={downloadLoading}>
                Download PDF
              </ActionButton>
            </>
          )}
        </ButtonsRow>
      </UploadingModalWrapper>
    </DistributionPageWrapper>
  );
};
