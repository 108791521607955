import React from 'react';
import {CoverContentWrapper, CoverPageWrapper, CoverText, CoverTextWrapper, Logo, TreeImg, Year} from './styles';
import logo from '../../ui-kit/AppLayout/assets/ENC_Logo_2017_FINAL_BLK.png';
import treesgold from '../../ui-kit/icons/treesgold.svg';

export const CoverPage = () => {
  return (
    <CoverPageWrapper>
      <CoverContentWrapper>
        <Logo src={logo} />
        <CoverTextWrapper>
          <Year>2022</Year>
          <CoverText>Enchant Portals</CoverText>
        </CoverTextWrapper>
      </CoverContentWrapper>
      <TreeImg src={treesgold} />
    </CoverPageWrapper>
  );
};
