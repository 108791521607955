import React from 'react';
import {Navigate} from 'react-router-dom';
import {route} from '../constants/routes';
import {WithChildren} from '../types/helpers';

export const PrivateRoute: React.FC<WithChildren & {isAuthorized?: boolean}> = ({children, isAuthorized}) => {
  if (!isAuthorized) return <Navigate to={route.login.path} replace />;

  return <>{children}</>;
};
