import React from 'react';
import styled from 'styled-components';
import {PrimaryButton} from '../../components/Common/Button/PrimaryButton';
import {useSendTickets} from '../../hooks/tickets';
import {Modal} from 'antd';
import {AssignSuccessModal} from '../../helpers/text';
import {useNavigate} from 'react-router-dom';
import {getGuestLink} from '../../constants/routes';
import {useGetCurrentGroup} from '../../hooks/state';

export const SendTicketsSubmit = () => {
  const {currentGroup} = useGetCurrentGroup();
  const navigate = useNavigate();

  const openSuccessModal = () =>
    Modal.success({
      title: AssignSuccessModal.title,
      content: AssignSuccessModal.content,
      onOk: () => navigate(getGuestLink(currentGroup.group)),
      okText: 'Done',
    });

  const {isValid, onSubmit, loading} = useSendTickets(openSuccessModal);

  //auto send temporary not working
  // const openConfirm = () =>
  //   Modal.confirm({
  //     title: SendConfirmModal.title,
  //     content: SendConfirmModal.content,
  //     onOk: onSubmit,
  //     okText: 'Yes',
  //     cancelText: 'No',
  //   });

  return (
    <>
      <Nup />
      <ButtonWrapper>
        <PrimaryButton onClick={onSubmit} disabled={!isValid || loading}>
          ASSIGN TICKETS
        </PrimaryButton>
      </ButtonWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  padding: 12px 16px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 20px 20px 20px #1111110f;
`;

const Nup = styled.div`
  height: 60px;
`;
