import React from 'react';
import {Navigate} from 'react-router-dom';
import {MagicLinkPage} from '../../components/MagicLink';
import {route} from '../../constants/routes';
import {useViewer} from '../../hooks/auth';
import {useSendMagicLink} from '../../hooks/magicLink';

export const MagicLink = () => {
  const viewer = useViewer();
  const {onSend, success, error} = useSendMagicLink();
  if (viewer) return <Navigate to={route.groups.path} />;
  return <MagicLinkPage onSend={onSend} success={success} error={error} />;
};
