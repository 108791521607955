import {palette} from './palette';
import {font} from './font';
import * as constants from '../../constants/env';

export const defaultTheme = {
  palette: palette,
  font,
};

export enum themes {
  enchant = 'enchant',
  coke = 'coke',
  lite = 'lite',
}

export const KIT_THEME = constants?.THEME || themes.enchant;
