import {themes} from '../ui-kit/theme/theme';

declare global {
  interface Window {
    _env_: {
      REACT_APP_SERVER_URL: string | undefined;
      REACT_APP_THEME: string | undefined;
    };
  }
}

export const SERVER_URL =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL;

export const THEME = ((typeof window !== 'undefined' && window._env_
  ? window._env_.REACT_APP_THEME
  : process.env.REACT_APP_THEME) || themes.enchant) as themes;
