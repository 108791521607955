import {useState} from 'react';
import {sendMultipleEmailsQuery, sendNotSentGuestEmailsQuery, uploadGuestEmails} from '../queries/emails';
import {getErrorResponse} from '../helpers/helpers';

export type useUploadEmailsT = {
  onUpload?: (values: any) => void;
  success?: {
    status?: 'completed' | 'failed';
    total?: number;
  };
  error?: string;
};

export const useUploadEmails = (id: string | any, onSuccess?: () => void) => {
  const [error, setError] = useState<any>(null);
  const [success, setSuccess] = useState<useUploadEmailsT['success']>({});
  const [uploadLoading, setUploadLoading] = useState(false);

  const onUpload = async (file: any) => {
    if (!id) return;
    setUploadLoading(true);
    try {
      const body = {groupId: id, body: {guestEmails: file}};
      const res = await uploadGuestEmails(body);
      // const res = await uploadGuestEmails({groupId: id, values});
      console.log(res);
      if (res) {
        setSuccess({status: 'completed', total: res.body?.total});
        onSuccess?.();
        setError('');
        setUploadLoading(false);
        return true;
      }
    } catch (e) {
      const errorMsg = getErrorResponse(e);
      setError(errorMsg || {message: 'error'});
      setUploadLoading(false);
      return false;
    }
  };

  return {onUpload, error, uploadLoading, success, setError};
};

export type useSendNotSentEmailsT = {
  send: (onSuccess?: () => void) => Promise<void>;
  loading?: boolean;
};

export const useSendNotSentEmails = (
  groupIDs: string[],
  showError?: (message?: string) => void,
): useSendNotSentEmailsT => {
  const [loading, setLoading] = useState(false);

  const send = async (onSuccess?: () => void) => {
    const groupId = groupIDs[0];
    setLoading(true);
    if (!groupId) return;
    try {
      if (groupIDs.length === 1) {
        const res = await sendNotSentGuestEmailsQuery({groupId});
        console.log(res);
        if (res) onSuccess?.();
      } else {
        const res = await Promise.all(
          groupIDs.map((el) => {
            return sendNotSentGuestEmailsQuery({groupId: el});
          }),
        );
        console.log(res);
        if (res) onSuccess?.();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
    }
  };
  return {send, loading};
};
export type sendMultipleT = (ids: string[]) => Promise<boolean>;
export type useMultipleSendbyEmailsT = {
  sendMultiple: sendMultipleT;
  loadingSend: boolean;
};
export const useMultipleSendbyEmails = (
  groupId?: string,
  onSuccess?: () => void,
  showError?: (message?: string) => void,
): useMultipleSendbyEmailsT => {
  const [loadingSend, setLoadingSend] = useState<boolean>(false);
  const sendMultiple = async (ids: string[]) => {
    if (!groupId) return false;
    setLoadingSend(true);
    try {
      const body = {ids: ids};
      const res = await sendMultipleEmailsQuery({groupId, body});
      if (res) onSuccess?.();
      onSuccess?.();
      setLoadingSend(false);
      return true;
    } catch (e) {
      setLoadingSend(false);
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };

  return {sendMultiple, loadingSend};
};
