import React, {useState} from 'react';
import styled from 'styled-components';
import {InputNumber} from '../../../../ui-kit/Input';

type PropsT = {
  min?: number;
  max?: number;
  value?: number;
  onChange?: (value?: number) => void;
  label?: string;
};

export const QuantityInput: React.FC<PropsT> = ({max, min = 0, value, onChange, label}) => {
  const [numValue, setValue] = useState<any>(value || 0);

  const onDecrease = () => {
    if (!numValue) return;
    setValue((prev: any) => prev - 1);
    onChange?.(numValue - 1);
  };

  const onIncrease = () => {
    if (numValue === '' || numValue === undefined) return;
    setValue((prev: any) => prev + 1);
    onChange?.(numValue + 1);
  };

  const handleChange = (val: any) => {
    setValue(val);
    onChange?.(val);
  };

  const increaseDisabled = max || max === 0 ? numValue + 1 > max : false;
  return (
    <Wrapper>
      <Label>
        {label} <br />
        <span>{max} remaining</span>
      </Label>
      <InputNumber
        min={min}
        max={max}
        addonBefore={<button onClick={onDecrease}>-</button>}
        addonAfter={
          <button onClick={onIncrease} disabled={increaseDisabled}>
            +
          </button>
        }
        controls={false}
        onChange={handleChange}
        value={numValue}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .ant-input-number-group-wrapper {
    transform: scale(1.2) translateX(-6px);
    .ant-input-number-out-of-range input {
      color: unset;
    }
  }
  margin: 16px 0;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  position: relative;
  span {
    bottom: -12px;
    left: 0px;
    font-size: 9px;
    color: #666;
    position: absolute;
    display: block;
    width: 120px;
  }
`;
