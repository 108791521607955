import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

type PropsT = {
  title?: string;
  subtitle?: string;
  link?: string;
  linkText?: string;
};

export const PageHeader: React.FC<PropsT> = ({title, subtitle, link, linkText}) => {
  return (
    <Wrapper>
      <Block>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Block>
      <LinkButton to={link || '#'}>{linkText}</LinkButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Block = styled.div``;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  color: #333333;
  max-width: 200px;
  margin-bottom: 2px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #333333;
  max-width: 230px;
`;

const LinkButton = styled(Link)`
  display: flex;
  align-items: center;

  width: auto;
  height: 29px;
  padding: 0 10px;
  box-sizing: border-box;

  border-radius: 4px;
  border: 1px solid #020e16;
  max-width: 100px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: #020e16;

  text-decoration: none;
`;
