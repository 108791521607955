import React from 'react';
import {Shapes} from './shapes';

type iconTypes = keyof typeof Shapes;
type PropsT = {
  type: iconTypes;
};

export const SvgIcon: React.FC<PropsT> = ({type, ...props}) => {
  const Icon = Shapes[type];

  return <Icon {...props} />;
};
