import styled from 'styled-components';
import {Button, Typography} from 'antd';
import {InfoButtonProps, ButtonTypes} from './index';
import {defaultTheme, themes} from '../theme/theme';
import {Link} from 'react-router-dom';
import {ArrowLeftOutlined, MoreOutlined} from '@ant-design/icons';
import {Media} from '../theme/breakpoints';
const {Text} = Typography;

export const StyledButton = styled(Button)<InfoButtonProps>`
  border-radius: 40px;
  background-color: ${({variant, theme}) => getBtnColor(variant, theme.current)};
  border-color: ${({variant}) => getBtnBorderColor(variant)};
  color: ${({variant}) => getBtnTextColor(variant)};
  :hover,
  :focus {
    background-color: ${({variant, theme}) => getBtnColor(variant, theme.current)};
    border-color: ${({variant}) => getBtnBorderColor(variant)};
    color: ${({variant}) => getBtnTextColor(variant)};
    opacity: 0.7;
  }
`;

const getBtnColor = (variant?: ButtonTypes, theme?: themes) => {
  const colors = {
    [themes.enchant]: {
      [ButtonTypes.primary]: defaultTheme.palette.common.brown,
      [ButtonTypes.secondary]: defaultTheme.palette.common.white,
      [ButtonTypes.tertiary]: defaultTheme.palette.background.midnightBlue,
      [ButtonTypes.quaternary]: null,
      [ButtonTypes.quinary]: null,
      [ButtonTypes.senary]: defaultTheme.palette.common.lightGreen,
    },
    [themes.coke]: {
      [ButtonTypes.primary]: defaultTheme.palette.common.black,
      [ButtonTypes.secondary]: defaultTheme.palette.common.white,
      [ButtonTypes.tertiary]: 'transparent',
      [ButtonTypes.quaternary]: null,
      [ButtonTypes.quinary]: null,
      [ButtonTypes.senary]: defaultTheme.palette.common.lightGreen,
    },
    [themes.lite]: {
      [ButtonTypes.primary]: defaultTheme.palette.common.black,
      [ButtonTypes.secondary]: defaultTheme.palette.common.white,
      [ButtonTypes.tertiary]: 'transparent',
      [ButtonTypes.quaternary]: null,
      [ButtonTypes.quinary]: null,
      [ButtonTypes.senary]: defaultTheme.palette.common.lightGreen,
    },
  };
  if (variant) return colors[theme || themes.enchant][variant];
  if (theme === themes.coke) return defaultTheme.palette.common.black;
  if (theme === themes.lite) return defaultTheme.palette.common.black;
  return defaultTheme.palette.common.brown;
};

const getBtnTextColor = (variant?: ButtonTypes) => {
  const colors = {
    [ButtonTypes.primary]: defaultTheme.palette.common.white,
    [ButtonTypes.secondary]: defaultTheme.palette.common.darkGray,
    [ButtonTypes.tertiary]: defaultTheme.palette.common.black,
    [ButtonTypes.quaternary]: defaultTheme.palette.common.white,
    [ButtonTypes.quinary]: null,
    [ButtonTypes.senary]: defaultTheme.palette.common.darkGreen,
  };
  return variant ? colors[variant] : defaultTheme.palette.common.white;
};

const getBtnBorderColor = (variant?: ButtonTypes) => {
  const colors = {
    [ButtonTypes.primary]: defaultTheme.palette.common.brown,
    [ButtonTypes.secondary]: defaultTheme.palette.common.brown,
    [ButtonTypes.tertiary]: defaultTheme.palette.common.skyBlue,
    [ButtonTypes.quaternary]: defaultTheme.palette.common.roseRed,
    [ButtonTypes.quinary]: null,
    [ButtonTypes.senary]: defaultTheme.palette.common.lightGreen,
  };
  return variant ? colors[variant] : defaultTheme.palette.common.brown;
};

const getInfoBtnTextColor = (variant?: ButtonTypes) => {
  const colors = {
    [ButtonTypes.primary]: defaultTheme.palette.common.white,
    [ButtonTypes.secondary]: defaultTheme.palette.common.white,
    [ButtonTypes.tertiary]: defaultTheme.palette.common.black,
    [ButtonTypes.quaternary]: defaultTheme.palette.common.white,
    [ButtonTypes.quinary]: defaultTheme.palette.common.white,
    [ButtonTypes.senary]: defaultTheme.palette.common.darkGreen,
  };
  return variant ? colors[variant] : defaultTheme.palette.common.white;
};

const getInfoBtnColor = (variant?: ButtonTypes) => {
  const colors = {
    [ButtonTypes.primary]: defaultTheme.palette.common.orange,
    [ButtonTypes.secondary]: defaultTheme.palette.common.darkGreen,
    [ButtonTypes.tertiary]: defaultTheme.palette.common.skyBlue,
    [ButtonTypes.quaternary]: defaultTheme.palette.common.roseRed,
    [ButtonTypes.quinary]: defaultTheme.palette.common.brown,
    [ButtonTypes.senary]: defaultTheme.palette.common.lightGreen,
  };
  return variant ? colors[variant] : 'transparent';
};

export const StyledInfoButton = styled(Button)<InfoButtonProps>`
  text-transform: capitalize;
  font-size: 12px;
  display: flex;
  align-items: center;
  pointer-events: none;
  cursor: auto;
  box-sizing: border-box;
  padding: 0 4px;
  border-radius: 4px;
  border-color: ${({variant}) => getInfoBtnColor(variant)};
  background-color: ${({variant}) => getInfoBtnColor(variant)};
  color: ${({variant}) => getInfoBtnTextColor(variant)};
  min-width: 60px;
  span {
    margin: 0 auto;
  }
  ${Media.down.m} {
    height: auto;
  }
`;

export const StyledLinkBack = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

export const ArrowBackIcon = styled(ArrowLeftOutlined)`
  margin-right: 17px;
  color: ${({theme}) => theme.palette.text.primary};
`;

export const LinkBackText = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: 16px;
  line-height: 20px;
`;

export const StyledLink = styled(Link)`
  & > button {
    position: static;
  }
`;

export const StyledButtonMore = styled(Button)`
  width: 20px;
  background-color: transparent;
`;

export const IconMore = styled(MoreOutlined)`
  color: ${({theme}) => theme.palette.common.primaryBlack};
  font-size: 24px;
`;
