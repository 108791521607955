import React from 'react';
import {FooterWrapper, PageWrapper, RightPart} from './styles';
import {LogoSection} from '../Auth/LogoSection';
import {useResetWithCode} from '../../hooks/auth';
import {NewPasswordForm} from './NewPassword';

type ResetPasswordWithCodePropsT = {
  resetData: useResetWithCode;
};

export const ResetPasswordWithCode: React.FC<ResetPasswordWithCodePropsT> = ({resetData}) => {
  return (
    <PageWrapper>
      <LogoSection />
      <RightPart>
        <NewPasswordForm resetData={resetData} />
        <FooterWrapper />
      </RightPart>
    </PageWrapper>
  );
};
