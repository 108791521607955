import {atom} from 'recoil';

export type PDF_files_StateT = {
  individual?: {
    name: string;
    url: string;
  };
  single?: {
    name: string;
    url: string;
  };
  groupId?: string;
};

export const pdf_state = atom<PDF_files_StateT | null>({
  key: 'pdf_files',
  default: null,
});
