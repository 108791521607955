import React from 'react';
import {PageWrapper} from '../Layout/Page';
import styled from 'styled-components';
import {Spin, Typography} from 'antd';
import {GroupInfoExpander} from '../Common/GroupInfoExpander';
import {TicketGroupT} from '../../external_usage';
import {GroupPicker} from './GroupPicker';
import {PrimaryButtonLink} from '../Common/Button/PrimaryButton';
import {getGuestLink, getSendLink} from '../../constants/routes';
import {StarLine} from '../Common/StarLine';
import {TITLE} from '../../../constants/text';

type PropsT = {
  groups?: TicketGroupT[];
  selectedGroup?: TicketGroupT;
  onGroupChange?: (id: string | number) => void;
  loading?: boolean;
};

export const GroupsView: React.FC<PropsT> = ({selectedGroup, groups, onGroupChange, loading}) => {
  const showPicker = (groups?.length || 0) > 1;
  return (
    <PageWrapper showIllustration>
      <Spin spinning={loading}>
        <PageTitle>Group Tickets Client Portal</PageTitle>
        {showPicker ? (
          <GroupPicker groups={groups} onGroupChange={onGroupChange} selectedGroup={selectedGroup} />
        ) : (
          <SelectedGroup>{selectedGroup?.clientName}</SelectedGroup>
        )}
        <GroupInfoExpander canExpand={false} openByDefault={true} group={selectedGroup} />
        <Text>Please choose from the following options:</Text>
        <ButtonLink to={getSendLink(selectedGroup)}>Send Tickets to Guests</ButtonLink>
        <ButtonLink to={getGuestLink(selectedGroup)}>Review Guest List</ButtonLink>
        <StarLine />
      </Spin>
      <Footer>
        © {TITLE} {new Date().getFullYear()}
      </Footer>
    </PageWrapper>
  );
};

const PageTitle = styled(Typography.Title).attrs({
  level: 3,
})`
  font-family: 'Majesti Banner';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #141414;
  margin-bottom: 8px;
`;

const Text = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  display: block;
  margin-top: 12px;
`;

const SelectedGroup = styled(Typography.Text)`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  display: block;
  margin-bottom: 12px;
`;

const ButtonLink = styled(PrimaryButtonLink)`
  margin: 16px 0;
`;

const Footer = styled.div`
  padding: 13px 0 4px 0;
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 2px;
  left: 0;
  background-color: #fff;
  font-size: 12px;
`;
