import {css} from 'styled-components';

export const buttonReset = css`
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
`;

export const exist = (value?: any) => value !== 'undefined' && value !== null && value !== undefined;

export const pluralize = (count: number, noun: string, suffix = 's') => `${count} ${noun}${count !== 1 ? suffix : ''}`;
