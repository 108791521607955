import React from 'react';
import styled from 'styled-components';
import {buttonReset} from '../../helpers/common';
import {SvgIcon} from '../Common/Icon';
import ENC_logo from '../../../ui-kit/AppLayout/assets/ENC_Logo_2017_FINAL_BLK.png';
import CC_logo from '../../../ui-kit/AppLayout/assets/CC_Logo_sm.png';
import Jingle_logo from '../../../ui-kit/AppLayout/assets/Jingle_Logo_sm.png';
import {themes} from '../../../ui-kit/theme/theme';
import {THEME} from '../../../constants/env';

type PropsT = {
  goBack?: () => void;
  handleLogout?: () => void;
};

const Logo = {
  [themes.coke]: CC_logo,
  [themes.enchant]: ENC_logo,
  [themes.lite]: Jingle_logo,
};

export const HeaderComponent: React.FC<PropsT> = ({goBack, handleLogout}) => {
  return (
    <Wrapper>
      {goBack ? (
        <IconButton onClick={goBack} pos="left">
          <SvgIcon type="arrowLeft" />
        </IconButton>
      ) : (
        <div />
      )}
      <Centered>
        <LogoImage src={Logo[THEME]} />
      </Centered>
      {handleLogout && (
        <IconButton onClick={handleLogout} pos="right">
          <SvgIcon type="logout" />
        </IconButton>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  background: ${({theme}) => {
    if (theme.current === themes.enchant) return theme.palette.background.black;
    if (theme.current === themes.coke) return theme.palette.background.black;
    if (theme.current === themes.lite) return theme.palette.background.black;
    return theme.palette.background.midnightBlue;
  }};
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconButton = styled.button<{pos: 'left' | 'right'}>`
  ${buttonReset}
  width: 48px;
  height: 48px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  ${({pos}) => {
    if (pos === 'left')
      return `
      justify-self: flex-start;
    `;
    if (pos === 'right')
      return `
      justify-self: flex-end;
    `;
  }};
`;

const LogoImage = styled.img`
  max-height: 45px;
`;
