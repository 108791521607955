import styled from 'styled-components';
import treesgold from '../../ui-kit/icons/treesgold.svg';
import {Typography} from 'antd';
const {Text} = Typography;

export const CoverPageWrapper = styled.div`
  /* background-image: url(${treesgold});
  background-size: 108px 188px;
  background-position: right 70px bottom 30px;
  background-repeat: no-repeat; */
  background: linear-gradient(117.61deg, rgba(4, 28, 44, 1) 21%, rgba(88, 117, 136, 1) 50%, rgba(4, 28, 44, 1) 79%);
  width: 100%;
  min-height: 788px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const CoverContentWrapper = styled.div`
  display: flex;
`;

export const Logo = styled.img`
  height: 181px;
  filter: drop-shadow(-4px -4px 24px rgba(255, 255, 255, 0.05)) drop-shadow(4px 4px 14px #000e17);
`;

export const CoverTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 37px;
  padding-top: 10px;
`;

export const Year = styled(Text)`
  font-size: 42px;
  line-height: 80px;
  color: ${({theme}) => theme.palette.common.white};
  text-shadow: -4px -4px 24px rgba(255, 255, 255, 0.05), 4px 4px 14px #000e17;
`;

export const CoverText = styled(Text)`
  font-size: 72px;
  line-height: 80px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  color: ${({theme}) => theme.palette.common.white};
  text-shadow: -4px -4px 24px rgba(255, 255, 255, 0.05), 4px 4px 14px #000e17;
`;

export const TreeImg = styled.img`
  position: absolute;
  right: 72px;
  bottom: 29px;
  width: 108px;
  height: 188px;
`;
