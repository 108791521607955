import React from 'react';
import {SendTicketStateT} from '../../states/tickets';
import styled from 'styled-components';
import {Form, Input} from 'antd';
import {QuantityInput} from '../Common/QuantityInput';
import {TicketsMapT} from '../../external_usage';

type PropsT = {
  values?: SendTicketStateT;
  handleChange?: (field?: string, value?: string | number) => void;
  changeQuantity?: (id: string, value?: number) => void;
  tickets?: TicketsMapT;
  error?: string;
};

export const TicketsForm: React.FC<PropsT> = ({values, handleChange, tickets, changeQuantity, error}) => {
  const [form] = Form.useForm();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange?.(e.target.name, e.target.value);
  };
  const onQuantityChange = (id?: string) => (value?: number) => id && changeQuantity?.(id, value);
  return (
    <FormWrapper>
      <Form form={form} validateTrigger="onChange" initialValues={values}>
        <Form.Item rules={[{required: true, message: 'Name is required!'}]} validateTrigger="onChange" name="name">
          <TextInput value={values?.name} onChange={onChange} placeholder="Enter name here..." name="name" />
        </Form.Item>
        <Form.Item
          required
          validateTrigger="onChange"
          name="email"
          rules={[
            {required: true, message: 'Email Address is required!'},
            {
              type: 'email',
              message: 'Please enter valid email!',
            },
          ]}>
          <TextInput value={values?.email} onChange={onChange} placeholder="Enter email here..." name="email" />
        </Form.Item>

        <QuantityLabel>Quantity</QuantityLabel>
        <QuantityInputs>
          {Object.keys(tickets || {})?.map((el) => (
            <div key={el}>
              <QuantityTypeLabel>{el}</QuantityTypeLabel>
              {tickets?.[el]?.map((ticket) => (
                <QuantityInput
                  label={ticket?.ticketTypeName}
                  max={ticket?.ticketTypeUploaded - ticket?.ticketTypeAssigned}
                  onChange={onQuantityChange(ticket?.ticketType)}
                  value={values?.quantity?.[ticket?.ticketType]}
                  key={ticket?.ticketType}
                />
              ))}
            </div>
          ))}
        </QuantityInputs>
      </Form>
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </FormWrapper>
  );
};

const FormWrapper = styled.div``;

const TextInput = styled(Input)`
  height: 38px;
  border-radius: 2px;
`;

const ErrorLabel = styled.div`
  color: ${({theme}) => theme.palette.common.roseRed};
  margin: 4px 0;
`;

const QuantityInputs = styled.div`
  margin-top: 16px;
  padding: 17px 24px 17px 24px;
  border-radius: 4px;
  border: 1px solid #e6e7e8;
`;
const QuantityLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
`;

const QuantityTypeLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #9a9fa2;
  margin-top: 8px;
  margin-bottom: 4px;
`;
