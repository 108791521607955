import React from 'react';
import {guestItemT} from '../../external_usage';
import styled from 'styled-components';

export const TicketsView = ({tickets}: {tickets?: guestItemT['ticketsMap']}) => {
  return (
    <>
      {Object.keys(tickets || {}).map((key) => (
        <Line key={key}>
          <Label>{key}</Label>
          {tickets?.[key]?.map((el) => (
            <Value key={el?.ticketName}>
              <span>{el?.ticketName}</span>
              <span>{el?.count}</span>
            </Value>
          ))}
        </Line>
      ))}
    </>
  );
};

const Line = styled.div`
  margin-top: 12px;
`;

const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #9a9fa2;
  margin-bottom: 5px;
`;

const Value = styled.div`
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Proxima Nova;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  color: #495258;
  span:last-of-type {
    font-weight: 700;
  }
`;
