//
// eslint-disable-next-line @typescript-eslint/ban-types
export type anyObject = object;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type anyArray = any[];

// eslint-disable-next-line @typescript-eslint/ban-types
export type anyNonNullish = {};

export type ArrayOfKeys<T extends anyObject> = [...(keyof T)[]];

export type ArrayValues<T extends anyArray> = T[number];

export type StringKeys<T extends anyObject, K extends keyof T> = Omit<T, K> & {[K2 in K]: string};

export type ChangedElement<T, E> = E & {name: T};

export type OnChange<
  T extends string,
  E extends HTMLElement = HTMLTextAreaElement & HTMLInputElement,
> = React.ChangeEvent<ChangedElement<T, E>>;

export type TStringObj = {
  [key: string]: string;
};

export type WithChildren = {
  children?: React.ReactNode;
};

export type SelectOptionT = {
  label: React.ReactNode;
  value?: string | number | null;
  disabled?: boolean;
};

export enum searchKeys {
  email = 'email',
  magicCode = 'magicCode',
}
