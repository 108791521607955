import React from 'react';
import {Spin} from 'antd';
import styled from 'styled-components';

export const PageLoader = () => {
  return (
    <Page>
      <Spin spinning />
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
