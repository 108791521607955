import {useRecoilState, useRecoilValue} from 'recoil';
import {currentGroupState, groupsState} from '../states/groups';
import {useGetCurrentGroup} from './state';

export const useChangeGroup = () => {
  const [{group}] = useRecoilState(currentGroupState);
  const {getGroup} = useGetCurrentGroup();
  const groups = useRecoilValue(groupsState);

  const onChange = async (id: string | number) => {
    const selected = groups?.groups?.find((el) => el?.id === id);
    if (selected) {
      getGroup(id);
    }
  };
  return {onChange, currentGroup: group};
};
