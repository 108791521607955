import React from 'react';
import {ForgotPassword} from '../../components/Forgot';
import {ResetPasswordWithCode} from '../../components/Forgot/ResetPassword';
import {useForgotPassword, useResetWithCode} from '../../hooks/auth';

export const Forgot = () => {
  const forgotData = useForgotPassword();

  return <ForgotPassword forgotData={forgotData} />;
};

export const ForgotReset = () => {
  const resetData = useResetWithCode();

  return <ResetPasswordWithCode resetData={resetData} />;
};
