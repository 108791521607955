import {themeToBrand} from '../helpers/common';
import {THEME} from './env';

export const AuthQueryList = {
  logout: () => '/v1/auth/logout',
  login: '/v1/auth/login',
  authorize: () => '/v1/auth/authorize',
  user: () => '/v1/auth/user',
  sendMagicLink: () => '/v1/auth/sendMagicLink',
  magicLinkLogin: () => '/v1/auth/magicLinkLogin',
  //reset pass
  updatePassword: `/v1/auth/resetPassword`,
  requestPasswordReset: `/v1/auth/sendResetPasswordEmail`,
  resetPasswordUsingCode: `/v1/auth/resetPasswordUsingCode`,
  resetPassword: (userId: string) => `/v1/user/${userId}/resetPassword`,
};

export const TicketQueryList = {
  createTicketGroup: () => `/v1/ticketGroup`,
  updateTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}`,
  deleteTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}`,
  shareTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}/share`,
  uploadEmails: (groupId: string) => `/v1/ticketGroup/${groupId}/uploadGuestEmails`,
  sendNotSentGuestEmails: (groupId: string) => `/v1/ticketGroup/${groupId}/sendNotSentGuestEmails`,
  getListTickets: (groupId: string) => `/v1/ticketGroup/${groupId}/ticket`,
  getTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}`,
  updateTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}`,
  deleteTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}`,
  sendTicket: (groupId: string, ticketId: string) => `/v1/ticketGroup/${groupId}/ticket/${ticketId}/send`,
  getGuests: (groupId: string) => `/v1/ticketGroup/${groupId}/guest`,
  addGuests: (groupId: string) => `/v1/ticketGroup/${groupId}/guest`,
  sendMultiple: (groupId: string) => `/v1/ticketGroup/${groupId}/sendMultipleGuestEmails`,
  getTicketTypes: (groupId: string) => `/v1/ticketGroup/${groupId}/ticketTypes`,
  updateGuest: (groupId: string, guestId: string) => `/v1/ticketGroup/${groupId}/guest/${guestId}`,
  deleteGuest: (groupId: string, guestId: string) => `/v1/ticketGroup/${groupId}/guest/${guestId}`,
  //file
  getCsv: (groupId: string) => `/v1/ticketGroup/${groupId}/guestCsv`,
  getEmailPreview: (groupId: string, guestId: string) => `/v1/ticketGroup/${groupId}/guest/${guestId}/emailPreview`,
};

export const GroupsQueryList = {
  getListTicketGroups: () => `/v1/ticketGroup?brand=${themeToBrand(THEME)}`,
  getTicketGroup: (groupId: string) => `/v1/ticketGroup/${groupId}`,
  generatePdf: (groupId: string) => `/v1/ticketGroup/${groupId}/generateTicketsPdf`,
  getPdfDownloadUrl: (groupId: string, individual?: boolean) =>
    `/v1/ticketGroup/${groupId}/getTicketsPdfUrlById${individual !== undefined ? `?individualPdfs=${individual}` : ''}`,
};
