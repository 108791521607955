import React from 'react';
import {ItemType} from 'antd/lib/menu/hooks/useItems';
import {Dropdown, Menu} from 'antd';
import {ButtonMore} from '../../../ui-kit/Button';

type PropsT = {
  onSend?: () => void;
  onEdit?: () => void;
  sendText?: string;
};

export const GuestMenu: React.FC<PropsT> = ({onEdit, onSend, sendText = 'Send Tickets', ...props}) => {
  const ddActions: ItemType[] = [
    {
      key: 1,
      label: <div onClick={onSend}>{sendText}</div>,
    },
    {key: 2, label: <div onClick={onEdit}>Edit</div>},
  ];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore {...props} />
    </Dropdown>
  );
};
