import React, {useState} from 'react';
import {useUploadEmails} from '../../../hooks/emails';
import {FileUpload} from '../../../ui-kit/Input/FileUpload';
import {
  UploadModalWrapper,
  UploadModalTitle,
  UploadModalDescr,
  UploadModalActions,
  UploadModalCancelBtn,
  UploadModalApplyBtn,
  StyledModalFileLink,
} from '../../../ui-kit/Modal/styles';
import {WideButton, WrapperLinks} from './styles';
// import {useGetCsv} from '../../../hooks/groups';
import {getModalText} from '../../../helpers/upload';

type UploadModalPropsT = {
  setFiles?: (files: any) => void;
  // onUpload: (value: any[]) => void;
  onCancel: () => void;
  id: string | undefined;
  refetch: () => void;
  onUploadSuccess: () => void;
  uploadTriggered?: boolean;
  isOnboarding?: boolean;
};

// type uploadResultState = {title: string; subTitle: string} | null;
export const links = {
  video: 'https://www.loom.com/embed/99df30469d714d7592ed6980087d266b',
  more: 'https://www.notion.so/enchantlabs/Promo-Code-How-to-Format-Your-CSV-for-Bulk-Upload-c15b083560e14336ad4124a34860f833',
};
export const UploadModal: React.FC<UploadModalPropsT> = ({
  onCancel,
  id,
  refetch,
  uploadTriggered,
  isOnboarding,
  onUploadSuccess,
}) => {
  const {onUpload, success, uploadLoading, error, setError} = useUploadEmails(id ? [id] : [], refetch);
  const [fileList, setFileList] = useState([]);
  const [fileTypeError, setFileTypeError] = useState(false);
  const isSuccess = success?.status === 'completed';
  const resetError = () => {
    setFileTypeError(false);
    setError('');
    setFileList([]);
  };
  const props = {
    onRemove: (file: any) => {
      // eslint-disable-next-line
      //@ts-ignore
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileTypeError(false);
    },
    // eslint-disable-next-line
    //@ts-ignore
    beforeUpload: (file) => {
      setFileTypeError(false);
      console.log(file);
      if (file.type !== 'text/csv' && !String(file.name).endsWith('.numbers') && !String(file.name).endsWith('.xlsx')) {
        setFileTypeError(true);
        return false;
      }
      // eslint-disable-next-line
      //@ts-ignore
      setFileList((prev) => [...prev, file]);
      return false;
    },
    error: fileTypeError
      ? {
          title: 'Invalid file uploaded. Please try again.',
          descr: `Please make sure it's a CSV, Numbers or Excel file and properly formatted`,
        }
      : null,
    fileList,
    accept: '.csv, .numbers, .xlsx',
    name: 'uploadEmails',
    // name: TicketGroupCreateFields.promoCodes,
  };
  const uploadEmails = () => onUpload?.(fileList);
  const {cancelText, ppTitle, uploadSuccess, uploadError, typeError, uploadText} = getModalText({
    uploadTriggered,
    isOnboarding,
    success: isSuccess,
    totalUploaded: success?.total || 0,
    fileTypeError,
    error: error?.message,
  });
  return (
    <UploadModalWrapper>
      <UploadModalTitle>{ppTitle}</UploadModalTitle>
      <FileUpload
        woTheme={true}
        onResultClick={resetError}
        success={uploadSuccess}
        loading={uploadLoading}
        descr="Add a properly formated file here for the your group tickets. You can upload a CSV, Numbers or Excel file with the list of names and emails only."
        {...props}
        error={uploadError || typeError}
      />
      {/* <WrapperLinks>
        <UploadModalDescr>
          <StyledModalFileLink href={url} ref={ref} download={name}>
            Click here to download the CSV template.
          </StyledModalFileLink>
        </UploadModalDescr>
        <StyledModalFileLink
          target="_blank"
          href="https://enchantlabs.notion.site/CSV-Guide-for-Tickets-Distribution-dd5e97f295524737a2e95b19240a3867">
          <QuestionCircleStyled />
        </StyledModalFileLink>
      </WrapperLinks> */}
      {!isSuccess ? (
        <>
          <WrapperLinks>
            <UploadModalDescr>Unsure how to use this template?</UploadModalDescr>
            <StyledModalFileLink target="_blank" href={links.video}>
              Follow video tutorial
            </StyledModalFileLink>
          </WrapperLinks>
          <UploadModalActions>
            <UploadModalCancelBtn onClick={onCancel}>{cancelText}</UploadModalCancelBtn>
            <UploadModalApplyBtn onClick={uploadEmails} disabled={!fileList?.length}>
              {uploadText}
            </UploadModalApplyBtn>
          </UploadModalActions>
        </>
      ) : (
        <WideButton onClick={onUploadSuccess}>Continue</WideButton>
      )}
    </UploadModalWrapper>
  );
};
