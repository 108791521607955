import React from 'react';
import styled from 'styled-components';
import {guestItemT} from '../../external_usage';
import {StatusLabels} from './StatusLabels';
import {TicketsView} from './TicketsLine';
import {GuestMenu} from './GuestMenu';

type PropsT = {
  guest?: guestItemT;
  handleSend?: (guest?: guestItemT) => void;
  handleEdit?: (guest?: guestItemT) => void;
};

export const GuestCard: React.FC<PropsT> = ({guest, handleSend, handleEdit}) => {
  const onSend = () => handleSend?.(guest);
  const onEdit = () => handleEdit?.(guest);
  const sendText = guest?.tickets?.some((c) => c.outboundStatus === 'sent') ? 'Resend Tickets' : 'Send Tickets';
  return (
    <Wrapper>
      <StatusLabels tickets={guest?.tickets} />
      <Text>{guest?.guestName}</Text>
      <Text>{guest?.guestEmail}</Text>
      <TicketsView tickets={guest?.ticketsMap} />
      <Menu sendText={sendText} onEdit={onEdit} onSend={onSend} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #cccfd0;

  background-color: #fff;
  position: relative;
`;

const Text = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const Menu = styled(GuestMenu)`
  position: absolute;
  right: 8px;
  top: 10px;
`;
