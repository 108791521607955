import {useEffect, useState} from 'react';
import {useChangeSendTicketState, useGetCurrentGroup} from './state';
import {MAX_TICKETS, addGroupGuests, getErrorResponse, validateEmail} from '../external_usage';
import {exist} from '../helpers/common';
import {notification} from 'antd';

export const useSendTicketForm = () => {
  const {state, changeName, changeEmail, changeQuantity} = useChangeSendTicketState();
  const handleChange = (field?: string, value?: string | number) => {
    if (field === 'name') return changeName(value);
    if (field === 'email') return changeEmail(value);
  };

  return {values: state, error: state?.error, handleChange, changeQuantity};
};

export const useSendTickets = (onSuccess?: () => void) => {
  const {
    currentGroup: {group},
    refetch,
  } = useGetCurrentGroup();
  const {state, clear} = useChangeSendTicketState();
  const [isValid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const checkQuantity = () => {
    const selectedQuantity = Object.values(state?.quantity || {}).reduce((c, n) => c + n, 0);
    if (selectedQuantity <= MAX_TICKETS) return true;

    notification.error({
      message: 'Error',
      description: `Please reduce the number of tickets you send. No more than ${MAX_TICKETS} tickets per email.`,
    });
    return false;
  };

  const onSubmit = async () => {
    if (!isValid || !exist(group?.id)) return;
    if (!state?.email || !state?.name || !state?.quantity) return;
    if (!checkQuantity()) return;

    try {
      setLoading(true);
      const guest = await addGroupGuests({
        groupId: String(group?.id),
        body: [{email: state?.email, name: state?.name, ...(state?.quantity || {})}],
      });
      console.log('guest:', guest);
      // const res = await sendMultipleEmailsQuery({groupId, body});
      refetch();
      onSuccess?.();
      clear();
    } catch (e) {
      const error = getErrorResponse(e);
      notification.error({
        message: 'Error',
        description: error?.message,
        placement: 'bottom',
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    if (!validateEmail(state?.email)) return setValid(false);
    if ((state?.name?.length || 0) < 1) return setValid(false);
    // const selectedQuantity = Object.values(state?.quantity || {}).reduce((c, n) => c + n, 0);
    // if (selectedQuantity > MAX_TICKETS) return setValid(false);
    setValid(true);
  };

  useEffect(() => {
    validate();
  }, [state?.email, state?.name, state?.quantity]);
  return {isValid, onSubmit, loading};
};
