import React from 'react';
import {
  LoginFormTitle,
  LoginFormWrapper,
  SubmitBtn,
  StyledAuthInput,
  StyledAuthFormItem,
  EmailResultLine,
  ResultText,
} from './styles';
import {Form} from 'antd';
import {useForgotPasswordT} from '../../hooks/auth';
import {ForgotPassFields} from '../../queries/types/auth';

const validateMessages = {
  types: {
    email: 'Invalid email. Please enter a valid email.',
    password: 'Invalid password. Please enter a valid password.',
  },
};

export type ForgotPasswordFormEmailPropsT = {
  forgotData: useForgotPasswordT;
};
const viaEmailResults = {
  success: 'Password reset message sent to your email',
  error: 'Sorry, please contact Enchant Labs for assistance',
};

export const ForgotPasswordFormEmail: React.FC<ForgotPasswordFormEmailPropsT> = ({forgotData}) => {
  const {statusViaEmail, resetByEmail, resetStatues} = forgotData;
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    resetByEmail(values);
  };
  return (
    <LoginFormWrapper
      form={form}
      onChange={resetStatues}
      onFinish={onFinish}
      validateMessages={validateMessages}
      layout={'vertical'}>
      <LoginFormTitle>Forgot Password</LoginFormTitle>
      <StyledAuthFormItem
        name={[ForgotPassFields.email]}
        rules={[
          {required: true, message: 'Email Address is required!'},
          {
            type: 'email',
          },
        ]}>
        <StyledAuthInput placeholder="Enter email" />
      </StyledAuthFormItem>
      {statusViaEmail > 0 && (
        <EmailResultLine>
          <ResultText>{viaEmailResults.success}</ResultText>
        </EmailResultLine>
      )}
      {statusViaEmail < 0 && (
        <EmailResultLine $isError>
          <ResultText>{viaEmailResults.error}</ResultText>
        </EmailResultLine>
      )}
      {statusViaEmail === 0 && (
        <StyledAuthFormItem>
          <SubmitBtn htmlType="submit">Send Forgot Password Link</SubmitBtn>
        </StyledAuthFormItem>
      )}
    </LoginFormWrapper>
  );
};
