import React, {useEffect} from 'react';
import {GroupsView} from '../../components/Groups';
import {useGetGroupsList, useSetInitialCurrentGroup} from '../../hooks/state';
import {useChangeGroup} from '../../hooks/groups';

export const GroupsPage = () => {
  useSetInitialCurrentGroup(); //set initial current group

  const {currentGroup, onChange} = useChangeGroup(); //select current group
  const {getGroupList, groups, loading} = useGetGroupsList();

  useEffect(() => {
    getGroupList(); //fetch and store group list
  }, []);
  return <GroupsView groups={groups} selectedGroup={currentGroup} onGroupChange={onChange} loading={loading} />;
};
