import {GroupDetailTableDataT, TicketStatusTypes} from '../types/tickets';

export const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email?: string) => {
  return emailRegexp.test(String(email).toLowerCase());
};

export const validatePassword = /(?=.*[a-z])([^\s]){8,16}$/;
// export const validatePassword = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;

export const getFilterTableData = (type: TicketStatusTypes, tableData?: GroupDetailTableDataT[]) => {
  switch (type) {
    case TicketStatusTypes.all:
      return tableData;
      break;

    case TicketStatusTypes.sent:
      return tableData?.filter((el, idx) => idx === 0 || el.actions?.ticketStatus === TicketStatusTypes.sent);
      break;

    case TicketStatusTypes.pending:
      return tableData?.filter((el, idx) => idx === 0 || el.actions?.ticketStatus === TicketStatusTypes.notsent);
      break;

    default:
      return tableData;
  }
};

export const getErrorResponse = (e: unknown): any => {
  try {
    const jsonErr = JSON.parse(JSON.stringify(e));
    return jsonErr?.response?.text && JSON.parse(jsonErr?.response?.text);
  } catch (e) {
    return false;
  }
};
