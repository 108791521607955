import {deleteQuery, getQuery, postQuery, putQuery} from './hooks';
import {GroupsQueryList, TicketQueryList} from '../constants/api';
import {
  AddGuestsReqT,
  GetEmailTemplateReq,
  deleteGuestReq,
  downloadPdfReq,
  generatePdfReq,
  getListTicketsReq,
  getTicketGroupReq,
  sendTicketReq,
  updateGuestReq,
  updateTicketReq,
} from './types/groups';

export const getListCodeGroups = async () => await getQuery(GroupsQueryList.getListTicketGroups());

export const getTicketGroup = async ({groupId}: getTicketGroupReq) =>
  await getQuery(GroupsQueryList.getTicketGroup(groupId));

export const getListTickets = async ({groupId}: getListTicketsReq) =>
  await getQuery(TicketQueryList.getListTickets(groupId));

export const sendTicket = async ({groupId, ticketId}: sendTicketReq) =>
  await postQuery(TicketQueryList.sendTicket(groupId, ticketId));

export const updateTicket = async ({groupId, ticketId, body}: updateTicketReq) =>
  await putQuery(TicketQueryList.updateTicket(groupId, ticketId)).send(body);

export const getGroupGuests = async ({groupId}: getTicketGroupReq) =>
  await getQuery(TicketQueryList.getGuests(groupId));

export const addGroupGuests = async ({groupId, body}: AddGuestsReqT) =>
  await postQuery(TicketQueryList.addGuests(groupId)).send(body);

export const getGroupCsv = async ({groupId}: getTicketGroupReq) =>
  await getQuery(TicketQueryList.getCsv(groupId)).responseType('blob');

export const updateGuest = async ({groupId, guestId, body}: updateGuestReq) =>
  await putQuery(TicketQueryList.updateGuest(groupId, guestId)).send(body);

export const deleteGuest = async ({groupId, guestId}: deleteGuestReq) =>
  await deleteQuery(TicketQueryList.deleteGuest(groupId, guestId));

export const generatePdf = async ({groupId}: generatePdfReq) => await getQuery(GroupsQueryList.generatePdf(groupId));

export const getPdfDownloadUrl = async ({groupId, individual}: downloadPdfReq) =>
  await getQuery(GroupsQueryList.getPdfDownloadUrl(groupId, individual));

export const getEmailPreview = async ({groupId, guestId}: GetEmailTemplateReq) =>
  await getQuery(TicketQueryList.getEmailPreview(groupId, guestId));
