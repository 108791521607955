import React from 'react';
import {route} from '../../constants/routes';
import {TicketGroupT, TicketTypeRow} from '../../queries/types/groups';
import {User} from '../../types/auth';
import {
  ContinueButton,
  FootNote,
  FootNoteBoldText,
  FootNoteText,
  WelcomeContent,
  WelcomeContentText,
  WelcomeContentWrapper,
  WelcomePageWrapper,
  WelcomeTitle,
  List,
  ListItem,
  ContentText,
  LoaderWrapper,
} from './styles';
import {TITLE} from '../../constants/text';
import {POLICY_LINK, TERMS_LINK} from '../../constants/links';
import {Loader} from '../../ui-kit/Loader';

type WelcomePageProps = {
  user: User | null;
  group?: TicketGroupT;
  metrics?: TicketTypeRow[];
  buttonRef: any;
  loading?: boolean;
};

export const WelcomePage: React.FC<WelcomePageProps> = ({group, metrics, loading}) => {
  const name = group?.contactPerson;
  return (
    <WelcomePageWrapper>
      <WelcomeContentWrapper>
        <WelcomeTitle>Welcome To Your {TITLE} Ticket Portal</WelcomeTitle>
        {loading && !group ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <WelcomeContent>
            <WelcomeContentText>Hi {name},</WelcomeContentText>
            <WelcomeContentText>
              Here’s your ticketing portal from {TITLE}. Here, you can print your group tickets, or send them via email
              to your guests.
            </WelcomeContentText>
            <ContentText>You have been issued the following {TITLE} tickets:</ContentText>
            <WelcomeContentText>
              <List>
                {metrics?.map((el) =>
                  el?.ticketTypeUploaded ? (
                    <ListItem>
                      {el?.ticketTypeUploaded} {el?.ticketDisplayName} tickets
                    </ListItem>
                  ) : null,
                )}
              </List>
            </WelcomeContentText>
            <ContinueButton type={'primary'} to={route.distribution.path}>
              Continue
            </ContinueButton>
            <FootNote>
              <FootNoteText>By continuing, I agree to {TITLE} </FootNoteText>
              <FootNoteBoldText href={TERMS_LINK} target={'_blank'}>
                terms and conditions{' '}
              </FootNoteBoldText>
              <FootNoteText>and </FootNoteText>
              <FootNoteBoldText href={POLICY_LINK} target={'_blank'}>
                privacy policy.
              </FootNoteBoldText>
            </FootNote>
          </WelcomeContent>
        )}
      </WelcomeContentWrapper>
    </WelcomePageWrapper>
  );
};
