import React from 'react';
import styled from 'styled-components';
import {SvgIcon} from '../Icon';

export const StarLine = () => {
  return (
    <Line>
      <span />
      <SvgIcon type="star" />
      <span />
    </Line>
  );
};

const Line = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;

  span {
    width: 43%;
    height: 1px;
    background-color: #a7abad;
  }
`;
