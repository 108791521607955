export const SendConfirmModal = {
  title: 'Are you sure you want to send this Tickets?',
  content:
    'Please make sure the email address is correct. With this action, your guest will receive an email with the ticket. This action cannot be undone.',
};

export const SendSuccessModal = {
  title: 'Tickets has been shared successfully!',
  content: 'Your guest will receive an email with their tickets enclosed.',
};

export const AssignSuccessModal = {
  title: 'The Tickets has been shared successfully!',
  content: '',
};

export const GuestSendConfirmModal = {
  title: (email?: string, send?: string) => `Are you ready to ${send} this ticket to ${email}?`,
  content:
    'Please make sure the email address is correct. With this action, your guest will receive an email with the ticket. This action cannot be undone.',
};

export const GuestSendSuccessModal = {
  title: (email?: string) => `Tickets were successfully reshared to ${email}!`,
  content: 'Your guest will receive an email with their tickets enclosed.',
};

export const GuestUpdateSuccessModal = {
  title: `The Guest details was successfully updated!`,
  content: 'Do you also want to resend Tickets for this guest?',
};
