import {
  GroupDetailTableDataT,
  ticketColumnsT,
  TicketSendStatuses,
  // GroupDetailTableDataT,
  TicketStatusTypes,
} from '../types/tickets';
import {SelectOptionT} from '../types/helpers';
import {AddGuestsBodyT, guestItemT, guestState, ticketGuestT, TicketT, TicketTypeRow} from '../queries/types/groups';
import {ticketState} from '../hooks/groups';
import {removeEmptyKeys} from './common';
import {EmailsMapT} from '../types/emails';
import {GroupedTicketTypesT} from './table';

export const getTableCodesFilterOptions = (): SelectOptionT[] => {
  return [
    {label: 'Show All', value: TicketStatusTypes.all},
    {label: 'Ticket sent', value: TicketStatusTypes.sent},
    // {label: 'Ticket redeemed', value: TicketStatusTypes.redeemed},
    {label: 'Pending', value: TicketStatusTypes.pending},
  ];
};

type guestIndex = {
  id?: number;
  email?: string;
  status?: TicketSendStatuses;
};

export const ticketsListToTableState = (
  values: TicketT[],
  guestInfo?: ticketGuestT,
  ticketTypes?: TicketTypeRow[],
): GroupDetailTableDataT[] => {
  const sortGuests = guestInfo?.guests
    ?.map((el) => {
      const updateTime = guestInfo?.ticketGroupGuests?.find((item) => item.id === el.guestId)?.updatedAt;
      return {...el, update: updateTime};
    })
    .sort((a, b) => +new Date(b.update + '') - +new Date(a.update + ''));

  const gEmails = sortGuests?.filter((el) => !!el?.guestEmail);
  const guestIndexes: guestIndex[] =
    gEmails?.map((guest, i) => {
      const isNotSend = guest?.tickets?.some((ticket) => ticket.outboundStatus === TicketSendStatuses.notsent);
      return {
        id: Number(guest?.guestId) || i,
        email: guest.guestEmail,
        status: isNotSend ? TicketSendStatuses.notsent : TicketSendStatuses.sent,
      };
    }) || [];

  const guestsToTable: GroupDetailTableDataT[] =
    sortGuests?.map((el, i) => {
      const item: guestIndex | undefined = guestIndexes[i];
      const id = el?.guestId || i;
      const admissionColumns = getTicketTypesColumns(el, ticketTypes, String(id));
      return {
        key: String(id),
        id: String(id),
        no: {no: String(Number(id)), id: String(id)},
        email: {email: el?.guestEmail, edit: true, id: String(id)},
        name: {name: el?.guestName, email: el?.guestEmail, edit: true, id: String(id)},
        ...admissionColumns,
        actions: {
          id: String(id),
          outboundStatus: item?.status,
          ticketStatus: item?.status,
          email: el?.guestEmail,
        },
      };
    }) || [];
  const admissionColumns = getTicketTypesColumnsAddrow(ticketTypes);
  const addRow: GroupDetailTableDataT = {
    key: 'add',
    id: 'add',
    no: {no: 'add', id: 'add'},
    email: {addRow: true},
    name: {addRow: true},
    ...admissionColumns,
    actions: {addRow: true},
  };
  const result = [addRow, ...guestsToTable];
  return result;
};

export const validateEmail = (email?: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const getTicketsCountText = (metrics?: TicketTypeRow[]) => {
  const counts = metrics
    ?.map((el) => (el?.ticketTypeUploaded ? `${el?.ticketDisplayName} ${el?.ticketTypeUploaded} tickets` : null))
    .filter((el) => !!el);
  const countText = counts?.length ? counts.join(', ') : 0;
  return `Ticket Counts: ${countText}`;
};

export const ticketStateToArray = (state: ticketState) => {
  const rows: {id?: string; email?: string; name?: string}[] = Object.keys(state).map((el) => ({
    id: el,
    email: state?.[el].email,
    name: state?.[el].name,
  }));
  return rows.filter((el) => validateEmail(el.email));
};

export const toAddGuestsBodyValues = (values?: guestState): guestState[] => {
  return [
    removeEmptyKeys<AddGuestsBodyT>({
      email: values?.email || '',
      name: values?.name || '',
      ...values,
      ////////// unset fields
      valid: undefined,
      validInfo: undefined,
    }),
  ];
};

export const getGuestTotalTickets = (values?: TicketTypeRow[]) => {
  return values?.reduce((prev, current) => prev + (current?.ticketTypeUploaded || 0), 0) || 0;
};

export const getTicketTypesColumns = (item?: guestItemT, ticketTypes?: TicketTypeRow[], id?: string) => {
  const columns = {};
  editableTicketTypes(ticketTypes)?.forEach((el) =>
    Object.assign(columns, {
      [el.ticketType]: {
        count: item?.tickets
          ?.filter((t) => correctTicketType(t?.ticketType) === el?.ticketType)
          .reduce((prev, next) => prev + Number(next.totalTickets), 0),
        id,
      },
    }),
  );

  return columns;
};

export const getTicketTypesColumnsAddrow = (ticketTypes?: TicketTypeRow[]) => {
  const tickets = {};
  editableTicketTypes(ticketTypes)?.forEach((el) => Object.assign(tickets, {[el.ticketType]: {addRow: true}})) || [];
  return tickets;
};

export const createTicketColumns = (types: string[]) =>
  types.map((el: string) => ({
    key: el?.toLowerCase()?.replaceAll(' ', '_'),
    title: el,
  })) as ticketColumnsT;

export type usedMetricsT = {[key: string]: number};
export const createMetricsUsed = (tickets?: TicketT[], ticketTypes?: TicketTypeRow[]) => {
  const used: usedMetricsT = {};
  const types = editableTicketTypes(ticketTypes);
  types?.forEach((el) => {
    used[el?.ticketType] = el?.ticketTypeAssigned;
  });
  return used;
};

export const toEmailsMap = (groups?: TicketT[]): EmailsMapT => {
  return groups?.map((el) => ({id: String(el?.id), email: el?.guestEmail})) || [];
};

export const getAdmissionsFromInitial = (initialState?: GroupDetailTableDataT) => {
  const columns = {};
  initialState
    ? Object.keys(initialState)
        // eslint-disable-next-line
        // @ts-ignore
        .filter((el) => !!initialState[el]?.count)
        // eslint-disable-next-line
        // @ts-ignore
        .forEach((el) => Object.assign(columns, {[el]: Number(initialState?.[el]?.count)}))
    : [];

  return columns;
};

export const correctTicketType = (ticketTypeName?: string) => {
  if (ticketTypeName?.toLowerCase().includes('minor') && ticketTypeName?.includes('skating')) return 'minor_skating';
  return ticketTypeName;
};

export const editableTicketTypes = (types?: TicketTypeRow[]) => types;

export const ticketTypesToTable = (tt?: GroupedTicketTypesT) => (tt ? Object.keys(tt) : []);
