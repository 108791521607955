import React from 'react';
import {TicketsForm} from '../../components/Form/TicketsForm';
import {useEditGuestForm} from '../../hooks/guests';
import {TicketGroupT} from '../../external_usage';
import {createTicketsMap} from '../../helpers/guests';

export const EditGuestForm = ({group}: {group: TicketGroupT}) => {
  const {values, error, handleChange, changeQuantity} = useEditGuestForm();
  const ticketsMap = createTicketsMap(group);

  return (
    <TicketsForm
      values={values}
      error={error}
      handleChange={handleChange}
      changeQuantity={changeQuantity}
      tickets={ticketsMap}
    />
  );
};
