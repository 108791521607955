import {TicketQueryList} from '../constants/api';
import {postQuery} from './hooks';
import {sendMultipleEmailsQueryReq, sendNotSentGuestEmailsReq, uploadGuestEmailsReq} from './types';

export const uploadGuestEmails = async ({groupId, body}: uploadGuestEmailsReq) =>
  await postQuery(TicketQueryList.uploadEmails(groupId)).field('guestEmails', body.guestEmails);

export const sendNotSentGuestEmailsQuery = async ({groupId}: sendNotSentGuestEmailsReq) =>
  await postQuery(TicketQueryList.sendNotSentGuestEmails(groupId));

export const sendMultipleEmailsQuery = async ({groupId, body}: sendMultipleEmailsQueryReq) =>
  await postQuery(TicketQueryList.sendMultiple(groupId)).send(body);
