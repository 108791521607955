import {atom} from 'recoil';
import {guestItemT} from '../external_usage';

export type SendTicketStateT = {
  email?: string;
  name?: string;
  quantity?: Record<string, number>;
  error?: string;
};

export const sendTicketState = atom<SendTicketStateT>({
  key: 'send-ticket-state',
  default: {},
});

export type guestsStateList = {
  guests?: guestItemT[];
  loading?: boolean;
};

export const guestsState = atom<guestsStateList>({
  key: 'guests-state',
  default: {loading: true},
});

export type editingGuestStateT = {
  guestId?: string | number;
  email?: string;
  name?: string;
  quantity?: Record<string, number>;
  error?: string;
};

export const editingGuestState = atom<editingGuestStateT>({
  key: 'editing-guest-state',
  default: {},
});
