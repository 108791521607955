import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {route} from './constants/routes';
import {MagicLink} from './containers/MagicLink';
import {SendgridMagicLink} from './containers/MagicLink/Sendgrid';
import {PrivateRoute} from './containers/PrivateRoute';
import {useViewer} from './hooks/auth';
import {Welcome} from './containers/Welcome';
import {CoverPage} from './components/Cover';
import {Groups} from './containers/Groups';
import {Group} from './containers/Groups/View';
import {LogIn} from './containers/Auth/LogIn';
import {Forgot, ForgotReset} from './containers/Auth/Forgot';
import {DistributionPage} from './containers/Distribution';
import {PrivateMobileRoutes} from './mobile/Routes';
import {IsMobileVersion} from './helpers/common';

const PublicRoutes = [
  <Route key="login" path={route.login.path} element={<LogIn />} />,
  <Route key="forgot-password" path={route.forgot.path} element={<Forgot />} />,
  <Route key="reset-password" path={route.resetPassword.path} element={<ForgotReset />} />,
  <Route key="magic-link" path={route.magicLink.path} element={<MagicLink />} />,
  <Route key="sendgrid-magic-link" path={route.magicLinkSendgrid.path} element={<SendgridMagicLink />} />,
];

const RoutesSwitch: React.FC = () => {
  const isAuth = useViewer();

  const PrivateRoutes = IsMobileVersion
    ? PrivateMobileRoutes(!!isAuth)
    : [
        <Route
          key="groups"
          path={route.groups.path}
          element={
            <PrivateRoute isAuthorized={!!isAuth}>
              <Groups />
            </PrivateRoute>
          }
        />,
        <Route
          key="group"
          path={route.groupView.path}
          element={
            <PrivateRoute isAuthorized={!!isAuth}>
              <Group />
            </PrivateRoute>
          }
        />,
        <Route
          key="welcome"
          path={route.welcomeScreen.path}
          element={
            <PrivateRoute isAuthorized={!!isAuth}>
              <Welcome />
            </PrivateRoute>
          }
        />,
        <Route
          key="cover"
          path={route.cover.path}
          element={
            <PrivateRoute isAuthorized={!!isAuth}>
              <CoverPage />
            </PrivateRoute>
          }
        />,
        <Route
          key="distribution"
          path={route.distribution.path}
          element={
            <PrivateRoute isAuthorized={!!isAuth}>
              <DistributionPage />
            </PrivateRoute>
          }
        />,
        <Route key="other" path={'*'} element={<Navigate to={'/'} replace />} />,
      ];
  return (
    <Routes>
      {PublicRoutes}
      {PrivateRoutes}
    </Routes>
  );
};

export default RoutesSwitch;
