import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import {TITLE} from '../../constants/text';

export enum ModalLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
}

type ShareModalProps = {
  modalLvl: ModalLvl;
  setModalLvl: (lvl: ModalLvl) => void;
  onSuccess?: () => void;
};

export const ShareModal: React.FC<ShareModalProps> = ({modalLvl, setModalLvl, onSuccess}) => {
  useEffect(() => {
    if (modalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure to send tickets to their paired emails?`,
        content: (
          <div>
            Please make sure the email addresses are correct. With this action, your guests will receive {TITLE}
            tickets. This action cannot be undone.
          </div>
        ),
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          onSuccess?.();
          setModalLvl(ModalLvl.success);
        },
        onCancel: () => setModalLvl(ModalLvl.closed),
      });
    }
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        title: `The tickets have been shared successfully!`,
        content: <div>Your guests will receive emails with {TITLE} tickets momentarily!</div>,
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
      });
    }
  }, [modalLvl]);

  return <></>;
};
