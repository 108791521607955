export const getModalText = ({
  uploadTriggered,
  success,
  error,
  fileTypeError,
  totalUploaded,
}: {
  uploadTriggered?: boolean;
  isOnboarding?: boolean;
  success: boolean;
  error: string;
  fileTypeError: boolean;
  totalUploaded: number;
}) => {
  const cancelText = uploadTriggered ? 'Cancel' : 'Cancel';
  const ppTitle = success ? 'Upload Template' : 'Upload Template';
  const uploadText = 'Upload Contacts';
  const uploadSuccess = success
    ? {
        title: 'The file has been uploaded successfully.',
        descr: `Your portal now contains ${totalUploaded || ''} contacts. `,
      }
    : undefined;
  const uploadError = error
    ? {
        title: 'File upload error.',
        descr: `${error || 'Please upload a new file'}`,
      }
    : null;
  const typeError = fileTypeError
    ? {
        title: 'Invalid file uploaded. Please try again.',
        descr: `Please make sure it's a CSV file and properly formatted`,
      }
    : null;

  return {cancelText, ppTitle, uploadSuccess, uploadError, typeError, uploadText};
};
