import {Modal, notification} from 'antd';
import {
  MAX_TICKETS,
  guestItemT,
  // getErrorResponse,
  // sendGuestEmails,
  // updateCodeGroupGuest,
  validateEmail,
  sendMultipleEmailsQuery,
  updateGuest,
} from '../external_usage';
import {exist} from '../helpers/common';
import {GuestSendSuccessModal, GuestSendConfirmModal, GuestUpdateSuccessModal} from '../helpers/text';
import {useEditingGuestState, useGetCurrentGroup, useGuestsState} from './state';
import {useNavigate} from 'react-router-dom';
import {getEditGuestLink, getGuestLink} from '../constants/routes';
import {useEffect, useState} from 'react';
import {sortOptions} from '../helpers/guests';

export const useGuestMenuActions = (refetchGuests?: () => Promise<void>) => {
  const navigate = useNavigate();
  const {currentGroup, refetch} = useGetCurrentGroup();
  const guestEditing = useEditingGuestState();
  const groupId = String(currentGroup?.group?.id);

  const successSendModal = (guest?: guestItemT) =>
    Modal.success({
      title: GuestSendSuccessModal.title(guest?.guestEmail),
      content: GuestSendSuccessModal?.content,
      okText: 'Done',
    });

  const onSend = async (guest?: guestItemT) => {
    if (!guest?.guestId || !exist(groupId) || !groupId) return;

    try {
      await sendMultipleEmailsQuery({groupId, body: {ids: [String(guest.guestId)]}});
      await refetch();
      await refetchGuests?.();
      successSendModal(guest);
    } catch (error) {
      console.error(error);
    }
  };

  const openConfirmSendModal = (guest?: guestItemT) =>
    Modal.confirm({
      title: GuestSendConfirmModal.title(guest?.guestEmail, 'send'),
      content: GuestSendConfirmModal?.content,
      onOk: async () => await onSend(guest),
      okText: 'Yes',
      cancelText: 'No',
    });

  const goGuestEdit = (guest?: guestItemT) => {
    const quantity: Record<string, number> = {};
    guest?.tickets.forEach((t) => {
      quantity[t.ticketType] = Number(t.totalTickets);
    });

    //set initial data
    guestEditing.setInitial({
      guestId: guest?.guestId,
      email: guest?.guestEmail,
      name: guest?.guestName,
      quantity,
    });

    navigate(getEditGuestLink(groupId, guest?.guestId));
  };
  return {openConfirmSendModal, goGuestEdit};
};

export const useEditGuestForm = () => {
  const {state, changeName, changeEmail, changeQuantity} = useEditingGuestState();
  const handleChange = (field?: string, value?: string | number) => {
    if (field === 'name') return changeName(value);
    if (field === 'email') return changeEmail(value);
  };

  return {values: state, error: state?.error, handleChange, changeQuantity};
};

export const useGuestUpdate = () => {
  const navigate = useNavigate();
  const {
    currentGroup: {group},
    refetch,
  } = useGetCurrentGroup();
  const {state, clear} = useEditingGuestState();
  const {refetchGuestList} = useGuestsState();
  const [isValid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const onCloseModal = () => {
    clear();
    navigate(getGuestLink(group));
  };

  //SUCCESS RESEND MODAL
  const successSendModal = (email?: string) =>
    Modal.success({
      title: GuestSendSuccessModal.title(email),
      content: GuestSendSuccessModal?.content,
      okText: 'Done',
      onOk: onCloseModal,
    });

  //CODES RESEND
  const onSend = async () => {
    if (!isValid || !exist(group?.id) || !exist(state?.guestId)) return;
    const groupId = String(group?.id);
    const guestId = Number(state?.guestId);
    try {
      await sendMultipleEmailsQuery({groupId, body: {ids: [String(guestId)]}});
      await refetch();
      await refetchGuestList?.();
      successSendModal(state?.email);
    } catch (error) {
      console.error(error);
    }
  };

  //SUCCESS UPDATE MODAL
  const openUpdateSuccessModal = () =>
    Modal.confirm({
      title: GuestUpdateSuccessModal.title,
      content: GuestUpdateSuccessModal.content,
      onOk: onSend,
      onCancel: onCloseModal,
      okText: 'Resend',
      cancelText: 'No',
    });

  //HANDLE UPDATE
  const onSubmit = async () => {
    if (!isValid || !exist(group?.id) || !exist(state?.guestId)) return;
    if (!state?.email || !state?.name || !state?.quantity) return;
    if (!checkQuantity()) return;

    try {
      setLoading(true);

      await updateGuest({
        groupId: String(group?.id),
        guestId: String(state?.guestId),
        body: {email: state?.email, name: state?.name, ...(state?.quantity || {})},
      });
      await refetch();
      await refetchGuestList?.();
      openUpdateSuccessModal();
    } catch (e) {
      console.error(e);
      notification.error({
        message: 'Error',
        description: `Something went wrong. Please try again later.`,
      });
    } finally {
      setLoading(false);
    }
  };

  //VALIDATE CODES QUANTITY
  const checkQuantity = () => {
    const selectedQuantity = Object.values(state?.quantity || {}).reduce((c, n) => c + n, 0);
    if (selectedQuantity <= MAX_TICKETS) return true;

    notification.error({
      message: 'Error',
      description: `Please reduce the number of tickets you send. No more than ${MAX_TICKETS} tickets per email.`,
    });
    return false;
  };
  const validate = () => {
    if (!validateEmail(state?.email)) return setValid(false);
    if ((state?.name?.length || 0) < 1) return setValid(false);
    // const selectedQuantity = Object.values(state?.quantity || {}).reduce((c, n) => c + n, 0);
    // if (selectedQuantity > MAX_TICKETS) return setValid(false);
    setValid(true);
  };

  useEffect(() => {
    validate();
  }, [state?.email, state?.name, state?.quantity]);
  return {isValid, onSubmit, loading};
};

export const useGuestsSearch = (guests?: guestItemT[]) => {
  const [searchBy, setSearchBy] = useState('');

  const onSearch = (value?: string) => setSearchBy(value || '');

  const searchedGuests = !searchBy
    ? guests
    : guests?.filter(
        (el) =>
          el?.guestEmail?.toLowerCase().includes(searchBy?.toLowerCase()) ||
          el?.guestName?.toLowerCase().includes(searchBy?.toLowerCase()),
      );

  return {searchedGuests, onSearch, searchBy};
};

export const useGuestsSort = (guests?: guestItemT[]) => {
  const [sortBy, setSort] = useState(sortOptions.recently);

  const handleSort = (value?: string) => setSort(value || sortOptions.recently);
  const sort = () => {
    if (sortBy === sortOptions.recently)
      return [...(guests || [])]?.sort((c, n) =>
        new Date(c?.updatedAt || 0).getTime() > new Date(n?.updatedAt || 0).getTime() ? -1 : 1,
      );
    if (sortBy === sortOptions.asc)
      return [...(guests || [])]?.sort((c, n) => (c?.guestName?.toLowerCase() > n?.guestName?.toLowerCase() ? 1 : -1));
    if (sortBy === sortOptions.desc)
      return [...(guests || [])]?.sort((c, n) => (c?.guestName?.toLowerCase() > n?.guestName?.toLowerCase() ? -1 : 1));
    return guests;
  };
  const sortedGuests = sort();

  return {handleSort, sortedGuests};
};
