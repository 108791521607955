import {MagicLinkBodyT, MagicLinkFields} from '../types/auth';
import {
  linkPortal,
  magicLinkLoginReq,
  RequestPasswordWithCodeReq,
  ResetPasswordReq,
  sendMagicLinkReq,
  SendResetPasswordEmailReq,
  UpdatePasswordReq,
} from '../queries/types/auth';

export const valuesToSendMagicLinkState = (values: MagicLinkBodyT): sendMagicLinkReq => {
  return {
    [MagicLinkFields.email]: values?.[MagicLinkFields.email] || '',
    [MagicLinkFields.portal]: linkPortal.groupTicket,
  };
};

export const valuesToLoginMagicLinkState = (values: MagicLinkBodyT): magicLinkLoginReq => {
  return {
    [MagicLinkFields.email]: values?.[MagicLinkFields.email] || '',
    [MagicLinkFields.magicCode]: values?.[MagicLinkFields.magicCode] || '',
  };
};

//password
export const toResetByEmailData = (values: any): SendResetPasswordEmailReq | null => {
  if (!values?.email) return null;
  return {
    email: values.email,
    portal: 'promoCode',
    userType: 'client',
  };
};

export const toResetWithCodeData = (values: any): RequestPasswordWithCodeReq | null => {
  if (!values?.email || !values?.code || !values?.newPassword) return null;
  return {
    email: values.email,
    code: values.code,
    newPassword: values.newPassword,
  };
};

export const toUpdatePasswordData = (values: any): UpdatePasswordReq | null => {
  if (!values?.email || !values?.existingPassword || !values?.newPassword) return null;
  return {
    email: values.email,
    existingPassword: values.code,
    newPassword: values.newPassword,
  };
};

export const toResetPasswordData = (userId?: string, values?: any): ResetPasswordReq | null => {
  if (!userId || !values?.password) return null;
  return {
    userId,
    body: {
      password: values.password,
    },
  };
};
