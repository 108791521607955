import React from 'react';
import {TicketActionTypes} from '../types/tickets';
import {ButtonTypes} from '../ui-kit/Button';
import {TicketTypeRow} from '../queries/types/groups';

export type TableColumnT = {
  title: string | React.ReactNode;
  dataIndex: string;
  key: string;
  render?: (a: any) => React.ReactNode;
  fixed?: 'left' | 'right';
};

export const ViewTicketMap = {
  [TicketActionTypes.sent]: ButtonTypes.tertiary,
  [TicketActionTypes.applied]: ButtonTypes.quaternary,
};

export type GroupedTicketTypesT = Record<string, TicketTypeRow[]>;
export const groupTicketTypesForTable = (types?: TicketTypeRow[]): GroupedTicketTypesT => {
  if (!types) return {};
  const templateNames: GroupedTicketTypesT = {};
  types.forEach((el) => {
    if (el.eventTemplateName === 'Fees' || !el.eventTemplateName) return;
    templateNames[el.eventTemplateName] = [...(templateNames[el.eventTemplateName] || []), el];
  });
  return templateNames;
};
