import {useEffect, useRef, useState} from 'react';
import {
  createMetricsUsed,
  getAdmissionsFromInitial,
  ticketsListToTableState,
  ticketStateToArray,
  toAddGuestsBodyValues,
  toEmailsMap,
  usedMetricsT,
  validateEmail,
} from '../helpers/tickets';
import {
  addGroupGuests,
  deleteGuest,
  getGroupCsv,
  getGroupGuests,
  getListCodeGroups,
  getListTickets,
  getTicketGroup,
  sendTicket,
  updateGuest,
  updateTicket,
} from '../queries/groups';
import {
  getListTicketGroupsRes,
  getListTicketsRes,
  getTicketGroupRes,
  GuestBodyT,
  guestFieldsT,
  guestItemT,
  guestState,
  ticketGuestT,
  TicketT,
  TicketTypeRow,
} from '../queries/types/groups';
import {GroupDetailTableDataT} from '../types/tickets';
import {EmailsMapT} from '../types/emails';
import {MAX_TICKETS} from '../helpers/common';
import {groupTicketTypesForTable} from '../helpers/table';

export const useGetGroups = () => {
  const [groups, setGroups] = useState<getListTicketGroupsRes>([]);
  const [loading, setLoading] = useState(true);
  const fetch = async () => {
    setLoading(true);
    try {
      const res = await getListCodeGroups();
      if (res) {
        setGroups(res.body as getListTicketGroupsRes);
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  }, []);
  const groupsOptions =
    groups?.length > 1
      ? groups
          .filter((obj) => obj.outboundStatus === 'sent')
          .map((el) => ({label: `${el?.clientName} (${el.ticketsUploaded} tickets)`, value: String(el?.id)}))
      : [];
  return {
    groups,
    refetch: fetch,
    loading,
    groupsOptions,
  };
};

export const useGetTicketGroup = (id?: string, skip?: boolean) => {
  const [group, setGroup] = useState<getTicketGroupRes>();
  const [loading, setLoading] = useState(false);
  const fetch = () => {
    if (!id || skip) return;
    setLoading(true);
    getTicketGroup({groupId: id}).then((res) => {
      setGroup(res?.body as getTicketGroupRes);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetch();
    const interval: NodeJS.Timer = setInterval(() => {
      fetch();
    }, 60000);
    return () => clearInterval(interval);
  }, [id, skip]);
  const groupedTicketTypes = groupTicketTypesForTable(group?.ticketTypes);
  return {group, loading, refetch: fetch, metrics: group?.ticketTypes, groupedTicketTypes};
};

export const useGetListTickets = (id?: string, ticketTypes?: TicketTypeRow[]) => {
  const [tickets, setTickets] = useState<TicketT[]>();
  const [guestInfo, setGuestInfo] = useState<ticketGuestT>();
  const [nonFilteredGuests, setNonFilteredGuests] = useState<ticketGuestT>();
  const [loading, setLoading] = useState(false);

  const fetch = async () => {
    if (!id) return;
    setLoading(true);
    await getGroupGuests({groupId: id}).then((res) => {
      setGuestInfo(res?.body);
      setNonFilteredGuests(res?.body);
    });
    getListTickets({groupId: id}).then((res) => {
      const body = res?.body as getListTicketsRes;
      setTickets(body);
      setLoading(false);
    });
  };

  const searchTickets = (v: string) => {
    if (guestInfo) {
      if (v === '') {
        setGuestInfo(nonFilteredGuests);
        return;
      }

      const searchValues = v.split(',').map((el) => el.toLowerCase().trim());
      const results: guestItemT[] = [];

      nonFilteredGuests?.guests.forEach((guest) => {
        const guestName = guest?.guestName?.toLowerCase();
        const guestEmail = guest?.guestEmail?.toLowerCase();

        const searched = searchValues.some((sv) => guestName.includes(sv) || guestEmail.includes(sv));
        if (searched) results.push(guest);
      });
      setGuestInfo({...guestInfo, guests: results});
    }
  };

  useEffect(() => {
    id && fetch();
  }, [id]);
  return {
    tickets,
    tableData: tickets ? ticketsListToTableState(tickets, guestInfo, ticketTypes) : [],
    loading,
    refetch: fetch,
    usedTickets: createMetricsUsed(tickets, ticketTypes),
    viewMetrics: {},
    emailsMap: toEmailsMap(tickets),
    searchTickets,
  };
};

export const useSendTicket = (groupId?: string, onSuccess?: () => void) => {
  const onSend = async (ticketId?: string) => {
    if (!groupId || !ticketId) return false;
    try {
      const res = await sendTicket({groupId, ticketId});
      if (res?.ok) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return {onSend};
};

export type useTicketsActionsT = {
  onDelete?: (ticketId?: string) => Promise<boolean>;
  onUpdate?: (props: {ticketId?: string; email?: string; name?: string}) => Promise<boolean>;
};

export const useTicketsActions = (groupId?: string, onSuccess?: () => void, showError?: (message?: string) => void) => {
  const onDelete = async (guestId?: string) => {
    if (!guestId || !groupId) return false;
    try {
      const res = await deleteGuest({groupId, guestId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };

  const onUpdate = async ({ticketId, email, name}: {ticketId?: string; email?: string; name?: string}) => {
    if (!ticketId || !groupId || !email) return false;
    try {
      const body = name ? {guestEmail: email, guestName: name} : {guestEmail: email};
      const res = await updateTicket({groupId, ticketId, body});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };
  return {onUpdate, onDelete};
};

export type tickerRow = {email?: string; name?: string};
export type ticketState = Record<any, tickerRow>;
export type ticketStateVal = {email?: string; name?: string; key: string};

export type useSetTicketStateT = {
  state: ticketState;
  handleSetEmail: (val: ticketStateVal) => void;
  handleSetName: (val: ticketStateVal) => void;
  reset: () => void;
};

export const useSetTicketState = (): useSetTicketStateT => {
  const [state, setState] = useState<ticketState>({});
  const handleSetEmail = (val: ticketStateVal) =>
    setState((prev) => ({...prev, [val.key]: {...prev[val.key], email: val.email}}));
  const handleSetName = (val: ticketStateVal) =>
    setState((prev) => ({...prev, [val.key]: {...prev[val.key], name: val.name}}));
  const reset = () => setState({});
  return {state, handleSetName, handleSetEmail, reset};
};

type multipleUpdateProps = {
  groupId?: string;
  onSuccess?: () => void;
};

export const useMultipleUpdate = ({groupId, onSuccess}: multipleUpdateProps) => {
  const {onUpdate} = useTicketsActions(groupId);
  const updateAll = async (emails: ticketState) => {
    if (!groupId) return false;
    try {
      const rows = ticketStateToArray(emails);
      const res = await Promise.all(rows.map((el) => onUpdate({ticketId: el?.id, email: el?.email, name: el?.name})));
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {updateAll};
};

type useMultipleSendProps = {
  groupId?: string;
  onSuccess?: () => void;
};

export const useMultipleSend = ({groupId, onSuccess}: useMultipleSendProps) => {
  const {onSend} = useSendTicket(groupId);
  const sendAll = async (state: ticketState) => {
    if (!groupId) return false;
    try {
      const rows = ticketStateToArray(state).map((el) => el.id);
      const res = await Promise.all(rows.map((id) => onSend(id)));
      if (res) onSuccess?.();
      onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {sendAll};
};

export type guestStateVal = {key: guestFieldsT; value?: string | number};
export type useAddGuestStateT = {
  guestState: guestState;
  guestSetState: (val: guestStateVal) => void;
  guestSetTickets?: (val: guestStateVal) => void;
  reset: () => void;
  onAddGuest: () => Promise<boolean>;
  loadingSave: boolean;
};

export const useAddGuests = (
  metrics?: TicketTypeRow[],
  metricsUsed?: usedMetricsT,
  groupId?: string,
  onSuccess?: () => void,
): useAddGuestStateT => {
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [state, setState] = useState<guestState>({valid: false, validInfo: false});
  const handleChange = (val: guestStateVal) => setState((prev: any) => ({...prev, [val.key]: val?.value}));

  const number_tickets = metrics
    ?.filter((el) => Number(state?.[el?.ticketType]))
    .map((el) => Number(state?.[el?.ticketType]));
  const sel = number_tickets?.join('');
  const ticketsSum = number_tickets?.reduce((p, n) => p + n, 0) || 0;

  useEffect(() => {
    const validInfo = !!validateEmail(state?.email) && (state.name?.length || 0) > 0;
    let isValid = validInfo && sel;
    metrics?.forEach((el) => {
      if (!el?.ticketType) return;
      const total = metrics?.find((m) => m.ticketType === el?.ticketType)?.ticketTypeUploaded || 0;
      const used = Number(metricsUsed?.[el?.ticketType]) || 0;
      if (Number(state?.[el?.ticketType] || 0) + used > total) isValid = false;
      if (ticketsSum > MAX_TICKETS) isValid = false;
    });
    setState((prev: any) => ({...prev, number_tickets: ticketsSum, valid: isValid, validInfo: validInfo}));
  }, [state.email, state.name, sel]);
  const reset = () => setState({valid: false, validInfo: false});

  const onAddGuest = async () => {
    if (!groupId || !state.valid) return false;
    setLoadingSave(true);
    try {
      const body = toAddGuestsBodyValues(state);
      const res = await addGroupGuests({groupId, body});
      if (res) {
        onSuccess?.();
        reset();
        setTimeout(() => {
          onSuccess?.(); //REFETCH TICKET COUNTS with delay
        }, 2500);
      }
      setLoadingSave(false);
      return true;
    } catch (e) {
      console.log(e);
      setLoadingSave(false);
      return false;
    }
  };
  return {guestState: state, guestSetState: handleChange, reset, onAddGuest, loadingSave};
};

export type TGetCSV = {
  onDownload: () => Promise<false | undefined>;
  ref: React.MutableRefObject<HTMLElement | null>;
  url: string | undefined;
  name: string | undefined;
};

export const useGetCsv = (groupId?: string) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();
  const [name, setFileName] = useState<string>();

  const onDownload = async () => {
    if (!groupId) return false;
    try {
      const res = await getGroupCsv({groupId});
      const tempUrl = URL.createObjectURL(res.body);
      setFileUrl(tempUrl);
      setFileName(res.headers?.filename || 'enchant-csv-email-template.csv');
    } catch (error) {
      console.log(error);
    }
  };

  return {onDownload, ref, url, name};
};

export const useEditGuest = (
  onUpdateGuest: onUpdateGuestT,
  initialState?: GroupDetailTableDataT,
  metrics?: TicketTypeRow[],
  metricsUsed?: usedMetricsT,
  emailsMap?: EmailsMapT,
  resetId?: () => void,
  sendWhenEdited?: () => void,
) => {
  const [loadingEdit, setLoadingEdit] = useState<boolean>(false);
  // const [initialEmail, setInitialEmail] = useState<string | undefined>('');
  const [eGuestState, setEditingState] = useState<guestState>({valid: true, validInfo: true});
  const handleEdit = (val: guestStateVal) => setEditingState((prev: any) => ({...prev, [val.key]: val?.value}));

  useEffect(() => {
    const admissions = getAdmissionsFromInitial(initialState);
    setEditingState((prev: guestState) => ({
      ...prev,
      ...admissions,
      name: initialState?.name?.name,
      email: initialState?.email?.email,
    }));
    // setInitialEmail(initialState?.email?.email);
  }, [initialState?.id]);

  const number_tickets = metrics
    ?.filter((el) => Number(eGuestState?.[el?.ticketType]))
    .map((el) => Number(eGuestState?.[el?.ticketType]));
  const sel = number_tickets?.join('');
  const ticketsSum = number_tickets?.reduce((p, n) => p + n, 0) || 0;

  useEffect(() => {
    const validInfo = !!validateEmail(eGuestState?.email) && (eGuestState.name?.length || 0) > 0;
    let isValid = validInfo && sel;

    metrics?.forEach((el) => {
      if (!el?.ticketType) return;
      const count = initialState?.[el?.ticketType as 'admission']?.count;
      const total = metrics?.find((m) => m.ticketType === el?.ticketType)?.ticketTypeUploaded || 0;
      const used = Number(metricsUsed?.[el?.ticketType]) || 0;
      if (Number(eGuestState?.[el?.ticketType] || 0) - Number(count || 0) + used > total) isValid = false;
      if (ticketsSum > MAX_TICKETS) isValid = false;
    });
    setEditingState((prev: any) => ({...prev, number_tickets: ticketsSum, valid: isValid, validInfo: validInfo}));
  }, [eGuestState.name, eGuestState.email, sel]);

  const onSave = async () => {
    setLoadingEdit(true);
    // const ids = emailsMap?.filter((el) => el?.email === initialEmail).map((el) => el?.id || '');
    const bodyData = Object.assign(eGuestState);
    delete bodyData.valid;
    delete bodyData.validInfo;
    const res = await onUpdateGuest(initialState?.id, bodyData, resetId);
    const useAutoSend = false;
    if (res && useAutoSend) {
      await sendWhenEdited?.();
      setEditingState({valid: true, validInfo: true});
    }
    if (!res) {
      resetId?.();
      setEditingState({valid: true, validInfo: true});
    }
    setLoadingEdit(false);
  };
  return {eGuestState, handleEdit, onSave, loadingEdit};
};

export type onUpdateTicketsT = (
  ids?: string[],
  email?: string,
  name?: string,
  onSuccess2?: () => void,
) => Promise<boolean>;

export const useUpdateTicketsInfo = (groupId?: string, onSuccess?: () => void) => {
  const onUpdateTickets = async (
    ids?: string[],
    email?: string,
    name?: string,
    onSuccess2?: () => void,
    showError?: (message?: string) => void,
  ) => {
    if (!groupId || !email) return false;
    try {
      await Promise.all(
        ids?.map((el) => updateTicket({groupId, ticketId: el || '', body: {guestEmail: email, guestName: name}})) || [],
      );
      onSuccess?.();
      onSuccess2?.();
      return true;
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };
  return {onUpdateTickets};
};

export type onUpdateGuestT = (id?: string, guestBody?: GuestBodyT, onSuccess2?: () => void) => Promise<boolean>;
export const useUpdateGuest = (groupId?: string, onSuccess?: () => void, showError?: (message?: string) => void) => {
  const onUpdateGuest = async (id?: string, guestBody?: GuestBodyT, onSuccess2?: () => void) => {
    if (!groupId || !id || !guestBody?.email) return false;
    try {
      await updateGuest({groupId, guestId: id, body: guestBody});
      onSuccess?.();
      onSuccess2?.();
      return true;
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      showError?.(errorMessage || '');
      return false;
    }
  };
  return {onUpdateGuest};
};
