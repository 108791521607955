import {TicketGroupT, TicketsMapT, guestItemT, ticketGroupGuestsT} from '../external_usage';

export const sortOptions = {
  asc: 'A-Z',
  desc: 'Z-A',
  recently: 'Recently Updated',
};

export const uniFieldsGuests = (
  guests?: guestItemT[],
  ticketGroupGuests?: ticketGroupGuestsT[],
): guestItemT[] | undefined => {
  return guests?.map((guest) => {
    const updatedAt = ticketGroupGuests?.find((tg) => tg.id === guest?.guestId)?.updatedAt;
    return {...guest, updatedAt: updatedAt ? new Date(updatedAt) : undefined};
  });
};

export const parseTicketsForView = (guests?: guestItemT[], group?: TicketGroupT) => {
  const ticketTypes = group?.ticketTypes;

  return guests?.map((guest) => {
    const record: guestItemT['ticketsMap'] = {};
    guest?.tickets?.forEach((gt) => {
      const ticketInfo = ticketTypes?.find((el) => el?.ticketType === gt?.ticketType);
      if (ticketInfo?.ticketGroupName.toLowerCase().includes('fees')) return;
      if (!ticketInfo?.ticketGroupName) return;
      record[ticketInfo.ticketGroupName] = [
        ...(record?.[ticketInfo.ticketGroupName] || []),
        {ticketName: ticketInfo?.ticketTypeName, ticketType: gt.ticketType, count: gt?.totalTickets},
      ];
    });
    return {...guest, ticketsMap: record};
  });
};

export const createTicketsMap = (group?: TicketGroupT) => {
  const ticketTypes = group?.ticketTypes;
  const record: TicketsMapT = {};

  ticketTypes?.forEach((tt) => {
    if (tt.ticketGroupName.toLowerCase().includes('fees')) return;
    record[tt.ticketGroupName] = [...(record?.[tt.ticketGroupName] || []), {...tt}];
  });
  return record;
};
