import React from 'react';
import styled from 'styled-components';
import {PrimaryButton} from '../../components/Common/Button/PrimaryButton';
import {useGuestUpdate} from '../../hooks/guests';

export const EditGuestSubmit = () => {
  const {isValid, onSubmit, loading} = useGuestUpdate();
  return (
    <>
      <Nup />
      <ButtonWrapper>
        <PrimaryButton onClick={onSubmit} disabled={!isValid || loading}>
          Update
        </PrimaryButton>
      </ButtonWrapper>
    </>
  );
};

const ButtonWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  padding: 12px 16px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 20px 20px 20px #1111110f;
`;

const Nup = styled.div`
  height: 60px;
`;
