import React from 'react';
import {PdfActions, PdfButton, PdfContentTitle, PdfContentWrapper, PdfGeneratedHint, PdfGeneratedTitle} from './styles';
import {usePdf} from '../../../hooks/onboarding';
import {Loader} from '../../../ui-kit/Loader';

export const PdfContent: React.FC<{groupId?: string; tickets?: number}> = ({groupId, tickets}) => {
  const {url_individual, url_single, name_single, name_individual, loading} = usePdf(groupId, false, true);
  return (
    <PdfContentWrapper>
      <PdfContentTitle>PDF Distribution Method was chosen to distribute to your customers.</PdfContentTitle>
      {!loading && (
        <>
          <PdfGeneratedTitle>
            <span>{tickets}</span> Tickets have been exported to a single PDF and individual PDFs
          </PdfGeneratedTitle>
          <PdfGeneratedHint>
            Single PDF: All tickets will be issued in one PDF file. This can be printed as a whole document with tickets
            on each page.
            <br />
            Individual PDfs: Each ticket will be issued as a separate PDF file, and downloaded as a zip file containing
            all PDFs. These can be printed or sent individually to guests.
          </PdfGeneratedHint>
        </>
      )}
      <PdfActions $loading={loading}>
        {loading && <Loader />}
        {/* <PdfButton variant={ButtonTypes.secondary} onClick={regenerate}>
          Regenerate PDF
        </PdfButton> */}
        <PdfButton href={url_single} download={name_single} disabled={!url_single}>
          Download Single PDF
        </PdfButton>
        <PdfButton href={url_individual} download={name_individual} disabled={!url_individual}>
          Download Individual PDFs
        </PdfButton>
      </PdfActions>
    </PdfContentWrapper>
  );
};
