export enum TicketStatusTypes {
  redeemed = 'redeemed',
  sent = 'sent',
  pending = 'pending',
  all = 'all',
  notsent = 'notsent',
}

export type TicketsCardT = {label?: string; value?: string; footNoteValue?: string; tooltip?: string};

export enum GroupDetailTableFields {
  no = 'no',
  email = 'email',
  name = 'name',
  tickets = 'tickets',
  admission = 'admission',
  skating = 'skating',
  parking = 'parking',
  actions = 'actions',
}

export const GroupDetailTableTitles = {
  no: 'No.',
  email: 'Email',
  name: 'Name',
  tickets: 'Tickets',
  admission: 'Admission',
  skating: 'Skating',
  parking: 'Parking',
  actions: 'Actions',
};

export enum CodeAccessTypes {
  shared = 'shared',
  pending = 'pending',
}

// export type GroupDetailTableDataT = {
//   key: string | number;
//   id: string;
//   no: {no?: string; id?: string};
//   email: {edit?: boolean; email?: string; id?: string};
//   name: {edit?: boolean; name?: string; id?: string};
//   admission: {admission?: number; edit?: boolean; id?: string};
// skating: {skating?: number; edit?: boolean; id?: string};
// parking: {parking?: number; edit?: boolean; id?: string};
// actions: {edit?: boolean; ticketStatus?: string; id?: string};
// };

export type ticketColumnsT = {key: string; title: string}[];
export type AdmissionRow = {count?: number; edit?: boolean; id?: string; addRow?: boolean};

export type GroupDetailTableDataT = {
  key?: string | number;
  id?: string;
  no?: {no?: string; id?: string; addRow?: boolean};
  email?: {edit?: boolean; email?: string; id?: string; addRow?: boolean};
  name?: {edit?: boolean; name?: string; email?: string | null; id?: string; addRow?: boolean};
  actions?: {edit?: boolean; ticketStatus?: string; id?: string; addRow?: boolean; email?: string};
  outboundStatus?: {outboundStatus?: string; id?: string; addRow?: boolean};
  admission?: {count?: number};
};

export type CodeCardT = {label?: string; value?: string; tooltip?: string};

export enum TicketActionTypes {
  sent = 'sent',
  applied = 'applied',
}

export enum TicketSendStatuses {
  sent = 'sent',
  queued = 'queued',
  notsent = 'notsent',
}

export enum TicketType {
  adult_admission = 'adult_admission',
  child_admission = 'child_admission',
  adult_vip_admission = 'adult_vip_admission',
  child_vip_admission = 'child_vip_admission',
  adult_skating = 'adult_skating',
  minor_skating = 'minor_skating',
  parking = 'parking',
}

export const distributionT = {
  email: 'email',
  pdf: 'pdf',
};
