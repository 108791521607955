import styled from 'styled-components';
import {Tag} from 'antd';

export const GroupsSwitchWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: 40px;
  background-color: ${({theme}) => theme.palette.background.gray};
  border-radius: 20px;
  padding: 18px;
  box-sizing: border-box;
`;

export const LinkItem = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.palette.background.white};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
`;

export const ValidationTag = styled(Tag)<{$valid?: boolean}>`
  background-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  border-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  color: ${({theme}) => theme.palette.common.white};
  border-radius: 4px;
  height: 25px;
  width: 70px;
  padding-top: 2px;
  padding-left: 10px;
`;
